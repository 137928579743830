//--------------------------------------------------------------
// common top section
//--------------------------------------------------------------
import { INTEGRATION_TEST_TYPES } from "./IntegrationConsts";

export const commonSection = [
  {
    field_name: "name",
    required: true,
    label: "Integration Name",
    db_field_name: "name",
    field_type: "text",
  },
  {
    value: "Restrict to...",
    field_name: "sectionTitle",
    field_type: "read-only",
  },
  {
    field_name: "deployment",
    required: true,
    label: "Deployment",
    db_field_name: "db_schema",
    field_type: "single-select",
  },
  {
    field_name: "servicegroups",
    required: true,
    label: "Service Groups",
    db_field_name: "service_group",
    field_type: "multi-select",
    tooltip:
      "Service groups are used to define associated log streams, applications, servers, etc. They are configured when you set up a log collector.",
  },
  {
    field_name: "id",
    field_type: "hidden",
    db_field_name: "siid",
  },
  {
    field_name: "auth_scheme",
    db_field_name: "auth_scheme",
    field_type: "hidden",
    value: "none",
  },
  {
    field_name: "category",
    db_field_name: "category",
    field_type: "hidden",
  },
  {
    field_name: "event_type",
    db_field_name: "out_event_type",
    field_type: "hidden",
  },
];

//--------------------------------------------------------------
// detection top section
//--------------------------------------------------------------
export const detectionSectionTop = [
  {
    field_name: "sendOnFirstOccurrence",
    required: false,
    label: "Send on 1st occurrence",
    db_field_name: "out_alert_on_new_incidents",
    field_type: "button-group",
    listValues: [
      { label: "No", value: false },
      { label: "Yes", value: true },
    ],
  },
];

//--------------------------------------------------------------
// enabled for signal/detection
//--------------------------------------------------------------
const enabledField = {
  field_name: "enabled",
  label: " ",
  field_type: "button-group",
  db_field_name: "enabled",
  listValues: [
    { label: "Disabled", value: false },
    { label: "Enabled", value: true },
  ],
};

export const inEnabled = {
  ...enabledField,
  db_field_name: "in_enabled",
};

export const outEnabled = {
  ...enabledField,
  db_field_name: "out_enabled",
};

//--------------------------------------------------------------
// signal bottom section
//--------------------------------------------------------------
export const signalSectionBottom = [
  {
    field_name: "token",
    required: false,
    label: "URL",
    db_field_name: "in_webhook",
    field_type: "copy",
  },
];

export const commonDashboardExtension = [
  {
    field_name: "tabTitle",
    field_type: "description",
  },
  {
    field_name: "name",
    required: true,
    label: "Integration Name",
    db_field_name: "name",
    field_type: "text",
  },
  {
    value: "Restrict to...",
    field_name: "sectionTitleCommon",
    field_type: "read-only",
  },
  {
    field_name: "deployment",
    required: true,
    label: "Deployment",
    db_field_name: "db_schema",
    field_type: "single-select",
  },
  {
    field_name: "endpoint",
    required: false,
    label: "Endpoint URL",
    db_field_name: "endpoint",
    field_type: "copy",
  },
  {
    field_name: "deploymentId",
    required: false,
    label: "Deployment ID",
    db_field_name: "db_schema",
    field_type: "copy",
  },
  {
    field_name: "accessToken",
    required: false,
    label: "Access Token",
    db_field_name: "access_token",
    field_type: "copy",
  },
  {
    field_name: "test-integration",
    required: true,
    label: "Create Sample Alert",
    test_type: INTEGRATION_TEST_TYPES.CREATE_ONLY,
    field_type: "button",
    tooltip: "Create an API key under Organization Settings in Datadog.",
  },
];
