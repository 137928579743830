import {
  detectionSectionTop,
  commonSection,
  signalSectionBottom,
  inEnabled,
  outEnabled,
} from "./CommonFields";
import { rebuildSection } from "./IntegrationDefinitionUtils";
import { IntegrationCategories } from "./IntegrationConsts";
//--------------------------------------------------------------
// opsgenie
//--------------------------------------------------------------
export const opsgenie = {
  common: rebuildSection(commonSection, {
    integration: "opsgenie",
    category: IntegrationCategories.INCIDENT_MANAGEMENT,
    out_event_type: "zebrium_incident",
    out_alert_on_new_incidents: false,
    in_region: "US",
    out_enabled: false,
    in_enabled: true,
  }),
  signal: {
    top: [inEnabled],
    APIConfig: [
      {
        field_name: "region",
        label: "Region",
        db_field_name: "in_region",
        field_type: "button-group",
        listValues: [
          { label: "US", value: "US" },
          { label: "EU", value: "EU" },
        ],
      },
      {
        field_name: "apiKey",
        required: true,
        label: "API Key",
        db_field_name: "auth_password",
        field_type: "text",
      },
    ],
    bottom: signalSectionBottom,
    order: ["top", "APIConfig", "bottom"], //ordering of ui sections
  },
  createIncident: {
    top: [outEnabled],
    webhookConfig: [
      {
        field_name: "webhookUrl",
        required: true,
        label: "Webhook URL",
        db_field_name: "out_webhook",
        field_type: "text",
      },
      ...detectionSectionTop,
    ],
    order: ["top", "webhookConfig"], //ordering of ui sections
  },
};
