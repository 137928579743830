import React, {useContext,useState,useEffect,useRef, useImperativeHandle, forwardRef}from "react";
import {
    Row,
    Button,
    Field,
    FieldTypes,
    PageContext,
    DataServiceContext,
    useModalDialog,} from "athena-next-ui-lib";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";



export function IntegrationTokenGen( props ) {
    const pageContext = useContext(PageContext);
    const dataServiceContext = useContext(DataServiceContext);
    const { openConfirmationDialog, closeModalDialog} = useModalDialog();

    const [token, setToken] = useState(props.accessToken);
    const [processing, setProcessing] = useState(false);

    useEffect(()=>{
        if( props.accesssToken ) {
            setToken(props.accesssToken);
        }
    },[ props.accesssToken])

    function regenToken( ) {

        return Promise.resolve()
            //.then(() =>props.showParentDialog({...props.origData, in_webhook: "", regenerating: true }))
            .then(()=>{
                setToken("");
                setProcessing(true);
                return;
            })
            .then(() =>dataServiceContext.fetch(
                'integration/update/tokengen',
                {siid: props.origData.siid}
            ))
            .then((response) => {
                if( response.response.code == 200) {
                    setToken(response.data[0].in_webhook)
                } else {
                    throw(response.response);
                }

                return response;
            })
            .then((dataOut) =>{
                setToken(dataOut?.data?.[0]?.in_webhook);

            })
            .catch((err)=>{

                throw(err);

            })
            .finally(()=>setProcessing(false));


    }


    const showConfirmDialog=()=>{
        return Promise.resolve()
            // .then(()=>closeModalDialog())
            .then(()=> openConfirmationDialog({
                icon: faQuestionCircle,
                submitLabel: "Continue",
                title: "Token Regen",
                content: "Are you sure you want to regen token?",
                closeOnSubmit:true,
                submit: ()=>{
                    // will open edit incident type dialog
                    regenToken()
                }

            }))


    }

    return <>
        <Row>
            <Field {...props}
                   type={FieldTypes.COPY}
                   value={token}
                   height={60} />
            <span style={{margin:"8px 8px"}}>
                    <Button  onClick={() => {
                        showConfirmDialog()
                    }} processing={processing}>Regenerate</Button>
                </span>
        </Row>

    </>;
}
