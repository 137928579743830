import React, {
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Column,
  Form,
  Row,
  Field,
  PageContext,
  DataServiceContext,
  useModalDialog,
  getErrorMessageDisplay,
} from "athena-next-ui-lib";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DialogDeployment = forwardRef((props, ref) => {
  const pageContext = useContext(PageContext);
  const dataServiceContext = useContext(DataServiceContext);
  const { openModalDialog, openConfirmationDialog } = useModalDialog();

  const [processing, setProcessing] = useState(false);

  const DEFAULT_UI_PERSONA = "alert_centric";

  const FormRef = useRef();
  const name = useRef();
  const uiPersona = useRef();

  const isAdd = props.action === "ADD";
  const uiPersonaValues = [
    { label: "Report Centric", value: "report_centric" },
    { label: "Alert Centric", value: "alert_centric" },
  ];

  useImperativeHandle(ref, () => ({
    showDialog,
  }));

  const deriveSelectedUiPersonaObj = (strValue) => {
    return uiPersonaValues.find(
      (persona) => persona.value === (strValue || DEFAULT_UI_PERSONA)
    );
  };

  const showDialog = (deployment) => {
    const title =
      props.action === "ADD" ? "Add Deployoment" : "Edit Deployment";
    const buttonLabel = props.action === "ADD" ? "Add" : "Update";

    const addOrUpdateDeployment = () => {
      let payload = FormRef.current.getValues();

      if (payload.errors.length > 0) {
        //form contains error(s), throw error
        throw { message: getErrorMessageDisplay(payload.errors) };
      }
      delete payload.errors;

      let apiUrl = null;
      if (isAdd) {
        apiUrl = "deployment/create/add";
      } else {
        apiUrl = "deployment/update";
        payload = { ...payload, id: deployment.id };
      }

      return Promise.resolve()
        .then(() => dataServiceContext.fetch(apiUrl, payload))
        .then((deploymentAddCall) => {
          if (deploymentAddCall.response.code === 200) {
            props.updateCallback && props.updateCallback(true);
          } else {
            throw deploymentAddCall.response;
          }
          return;
        });
    };

    const modalDialogSettings = {
      title: title,
      icon: null,
      submitLabel: buttonLabel,
      submit: addOrUpdateDeployment,
      content: (
        <Form ref={FormRef} refs={[name, uiPersona]}>
          <Row>
            <Column width={"400px"}>
              <Field
                label="Name"
                ref={name}
                required={isAdd}
                type={"text"}
                name={"name"}
                width={"200px"}
                disabled={processing}
                value={deployment ? deployment.name : ""}
              />
            </Column>
          </Row>
          {/* <Row>
                    <Column width={"400px"}>
                        <Field ref={uiPersona}
                               type={FieldTypes.BUTTON_GROUP}
                               label="UI Persona"
                               name={"ui_persona"}
                               css={["md", "dark"]}
                               values={uiPersonaValues}
                               value={deriveSelectedUiPersonaObj(deployment ? deployment.ui_persona : DEFAULT_UI_PERSONA)}
                              />
                    </Column>
                </Row> */}
        </Form>
      ),
    };

    return Promise.resolve().then(() => openModalDialog(modalDialogSettings));
  };

  if (isAdd) {
    return (
      <Button onClick={() => showDialog({})} processing={processing}>
        <FontAwesomeIcon icon={faPlus} />
        <span>Add Deployment</span>
      </Button>
    );
  } else {
    return null;
  }
});
