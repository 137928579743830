import React, {
  forwardRef,
  useRef,
  useContext,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  Field,
  Row,
  Column,
  useModalDialog,
  DataServiceContext,
  PageContext,
} from "athena-next-ui-lib";

import {
  IntegrationForm,
  DeploymentSelector,
  IntegrationTokenGen,
  ServiceGroupSelector,
  TestIntegration,
  IntegrationFormContext,
} from "./index";

export const IntegrationDataDriven = forwardRef((props, ref) => {
  const integrationContext = useContext(IntegrationFormContext);
  const dataServiceContext = useContext(DataServiceContext);
  const pageContext = useContext(PageContext);
  const { openModalDialog } = useModalDialog();

  const { integrationType, data, integrationDefinition, processing, action } =
    props;
  const isAdd = !integrationContext?.sharedIntegrationData?.siid;
  const [authMethod, setAuthMethod] = useState(
    integrationContext?.sharedIntegrationData?.auth_scheme
  ); // changing auth method will hide/show auth fields

  const [sharedData, setSharedData] = useState(
    integrationContext?.sharedIntegrationData || {}
  );

  const [formErrors, setFormErrors] = useState(null);

  const formRef = useRef();

  const isValid = () => {
    const values = formRef.current.getValues();

    if (values.errors?.length > 0) {
      setFormErrors(values.errors);

      // setErrorMessages( getErrorMessageDisplay( values.errors ) );
      return false;
    } else {
      setFormErrors([]);
      return true;
    }
  };

  useImperativeHandle(ref, () => ({
    isValid,
  }));

  useEffect(() => {
    setSharedData(integrationContext?.sharedIntegrationData || {});
  }, [integrationContext?.sharedIntegrationData]);

  //-----------------------------------------------------
  // signal only: token
  //-----------------------------------------------------
  const fieldsExcludedWhenAdd = ["id", "token", "endpoint", "accessToken"];

  const widthProps = {
    labelWidth: "150px",
    fieldWidth: "250px",
  };

  const integrationTemplate = integrationDefinition;

  //--------------------------------------------
  // derive number of fields from an array, skip id, token, accessToken when ADD.
  // skip sectionTitle
  //--------------------------------------------
  const getFieldLengthFromArray = (fieldsArray) => {
    let fieldsCount = fieldsArray.length;
    if (isAdd) {
      //skip excluded fields for Add
      const excludedFields = fieldsArray.filter(
        (fld) => fieldsExcludedWhenAdd.indexOf(fld.field_name) > -1
      );
      fieldsCount = fieldsCount - excludedFields.length;
    }

    //skip counting section/tab title fields
    const noRefFields = fieldsArray.filter(
      (fld) => ["read-only", "description"].indexOf(fld.field_type) > -1
    );
    fieldsCount = fieldsCount - noRefFields.length;

    return fieldsCount;
  };

  //--------------------------------------------
  // need to figure out number of fields in order
  // to create refs array dynamically
  //--------------------------------------------
  const getFormFieldLength = () => {
    let numOfFields = 0;
    if (Array.isArray(integrationTemplate)) {
      //if pass in as an array
      numOfFields = getFieldLengthFromArray(integrationTemplate);
    } else {
      //inbound/outbound
      numOfFields = integrationTemplate.order.reduce((accumulator, section) => {
        let fieldsCount = getFieldLengthFromArray(integrationTemplate[section]);
        return (
          accumulator + getFieldLengthFromArray(integrationTemplate[section])
        );
      }, 0);
    }

    return numOfFields;
  };

  const formFieldLength = getFormFieldLength();
  const refsArr = useRef([]);
  // estimated up to 10 fields on the form
  refsArr.current[0] = useRef();
  refsArr.current[1] = useRef();
  refsArr.current[2] = useRef();
  refsArr.current[3] = useRef();
  refsArr.current[4] = useRef();
  refsArr.current[5] = useRef();
  refsArr.current[6] = useRef();
  refsArr.current[7] = useRef();
  refsArr.current[8] = useRef();
  refsArr.current[9] = useRef();
  refsArr.current.length = formFieldLength;

  // for (let i = 0; i < formFieldLength; i++) {
  //   refsArr.current[i] = useRef();
  // }
  //----------------------------------------------------
  // predefined fields:
  //      field_type: password, hidden, button-group
  //      field_name: sectionTitle, deployment, servicegroups, token, authMethod,
  //                   authUsername, authPassword, authPrefix, authToken,
  //                   sendOnFirstOccurrence
  //----------------------------------------------------
  const getField = (fld, ref) => {
    const fldName = fld.field_name;
    const dbFldName = fld.db_field_name;

    const fieldProps = {
      ...props,
      ...widthProps,
      hint: fld.hint || null,
      tooltip: fld.tooltip || null,
      required: fld.required || false,
      type: fld.field_type,
      label: fld.label,
      name: fld.db_field_name,
      onUpdateValue: (value) => {
        integrationContext.setSharedIntegrationData({ [dbFldName]: value });
      },
    };

    let returnField = null;
    // if( fldName.startsWith("tabTitle") ){
    //     returnField = <p style={{width:"0px",minWidth:"100%", lineHeight:"18px", margin:"8px 0", textAlign: "justify"}}>{fld.value}</p>
    // }
    if (fldName.startsWith("sectionTitle")) {
      returnField = <Field type={"read-only"} value={<b>{fld.value}</b>} />;
    } else if (fldName === "deployment") {
      returnField = (
        <DeploymentSelector
          {...fieldProps}
          deploymentSelectorRef={ref}
          selectedDeployment={sharedData?.[fld.db_field_name] || null}
          onUpdateValue={(selObjArr) =>
            integrationContext.setSharedIntegrationData({
              [fld.db_field_name]: selObjArr[0].id,
            })
          }
          allowSelectAll={false}
        />
      );
    } else if (fldName === "servicegroups") {
      returnField = (
        <ServiceGroupSelector
          {...props}
          {...fieldProps}
          serviceGroupsRef={ref}
          onUpdateValue={(selObjArr) => {
            const updatedValues = selObjArr.map((o) => o.name).join(",");
            integrationContext.setSharedIntegrationData({
              [fld.db_field_name]: updatedValues,
            });
          }}
          selectedServiceGroups={sharedData?.[fld.db_field_name] || null}
        />
      );
    } else if (fld.field_name === "token") {
      returnField = (
        <IntegrationTokenGen
          {...fieldProps}
          origData={{ ...sharedData }}
          accesssToken={sharedData?.[fld.db_field_name]}
          tokenGenRef={ref}
          regenerating={sharedData?.regenerating}
          showParentDialog={props.showEditDialog}
          updateCallback={props.regenCallback}
        />
      );
    } else if (fld.field_name === "authMethod") {
      const btnAuthMethods = [
        { label: "NONE", value: "none" },
        { label: "BASIC", value: "basic" },
        { label: "TOKEN", value: "token" },
      ];
      const selectedAuthMethodButton = btnAuthMethods.find(
        (item) => item?.value == authMethod
      );

      returnField = (
        <Field
          {...fieldProps}
          ref={ref}
          css={["md", "dark"]}
          onUpdateValue={(selObj) => {
            setAuthMethod(selObj.value);
            integrationContext.setSharedIntegrationData({
              [dbFldName]: selObj.value,
            });
          }}
          values={btnAuthMethods}
          value={selectedAuthMethodButton}
        />
      );
    } else if (["authUsername", "authPassword"].indexOf(fld.field_name) > -1) {
      if (authMethod === "basic") {
        returnField = (
          <Field
            {...fieldProps}
            required={true}
            ref={ref}
            value={sharedData?.[fld.db_field_name] || ""}
          />
        );
      } else {
        returnField = null;
      }
    } else if (fld.field_name === "authPrefix") {
      if (authMethod !== "none") {
        returnField = (
          <Field
            {...fieldProps}
            required={true}
            ref={ref}
            value={sharedData?.[fld.db_field_name] || ""}
          />
        );
      } else {
        returnField = null;
      }
    } else if (fld.field_name === "authToken") {
      if (authMethod === "token") {
        returnField = (
          <Field
            {...fieldProps}
            required={true}
            ref={ref}
            value={sharedData[fld.db_field_name] || ""}
          />
        );
      } else {
        returnField = null;
      }
    } else if (fld.field_name === "sendOnFirstOccurrence") {
      const firstOccurrenceValue = sharedData[fld.db_field_name];
      const btnGrpFirstOccurrence = fld.listValues;
      const defaultBtnGrpFirstOccurrence = btnGrpFirstOccurrence.find(
        (item) => item?.value == firstOccurrenceValue
      );

      returnField = (
        <Column>
          {/*------------first occurrence--------------*/}
          <Row>
            <Field
              {...fieldProps}
              labelWidth={"auto"}
              ref={ref}
              css={["md", "dark"]}
              values={btnGrpFirstOccurrence}
              onUpdateValue={(selObj) => {
                integrationContext.setSharedIntegrationData({
                  [dbFldName]: selObj.value,
                });
              }}
              value={defaultBtnGrpFirstOccurrence}
            />
          </Row>
          <p style={{ maxWidth: "450px", marginTop: 0, marginLeft: "150px" }}>
            You can choose to send notifications the first time a new type of
            proactive root cause report is detected. We recommend setting this
            to “Yes” for proactive notification of potential new problems. If
            you want to be notified on subsequent occurrences, do this from the
            relevant root cause report.
          </p>
        </Column>
      );
    } else if (fld.field_type === "button-group") {
      //const isValueUndefinedOrNull = sharedData?.[fld.db_field_name] === null || sharedData?.[fld.db_field_name] === undefined;
      const selectedValue = sharedData?.[fld.db_field_name];
      const selectedButton = fld.listValues.find(
        (item) => item.value == selectedValue
      );

      returnField = (
        <Field
          {...fieldProps}
          ref={ref}
          css={["md", "dark"]}
          values={fld.listValues}
          onUpdateValue={(selObj) => {
            integrationContext.setSharedIntegrationData({
              [dbFldName]: selObj.value,
            });
          }}
          value={selectedButton}
        />
      );
    } else if (fld.field_type === "single-select") {
      const selectedValue = fld.listValues.filter(
        (item) => item.value === sharedData?.[fld.db_field_name]
      );

      returnField = (
        <Field
          {...fieldProps}
          ref={ref}
          value={selectedValue}
          values={fld.listValues}
          onUpdateValue={(selObjArr) => {
            integrationContext.setSharedIntegrationData({
              [dbFldName]: selObjArr?.[0].value,
            });
          }}
        />
      );
    } else if (fld.field_type === "hidden") {
      returnField = (
        <Field
          {...fieldProps}
          ref={ref}
          value={fld.value || sharedData[fld.db_field_name] || ""}
        />
      );
    } else if (fld.field_name === "endpoint") {
      returnField = (
        <Field {...fieldProps} height={30} value={pageContext.env.ZAPI_URL} />
      );
    } else if (fld.field_name === "deploymentId") {
      returnField =
        action === "EDIT" ? (
          <Field
            {...fieldProps}
            height={30}
            value={sharedData[fld.db_field_name]}
          />
        ) : null;
    } else if (fld.field_name === "test-integration") {
      // returnField = <>
      //     <div style={{width:widthProps.labelWidth}}/>
      //     <Button
      //     {...fieldProps}
      //     processing={testButtonClicked}
      //     ref={ref}
      //     onClick={()=>testIntegrationConnectAndCreate()}>{fld.label}</Button>
      // </>

      returnField = (
        <TestIntegration
          fld={fld}
          fieldProps={fieldProps}
          sharedData={sharedData}
          testType={fld.test_type}
          isIntegrationFormValid={props.isIntegrationFormValid}
        />
      );
    } else {
      let derivedValue = sharedData?.[fld.db_field_name] || fld.value || "";

      if (dbFldName === "out_email_list") {
        if (props.data?.[fld.db_field_name] === derivedValue) {
          //when initially shown
          derivedValue = derivedValue.replace(/, */g, "\n");
        }
      }

      returnField = (
        <Field
          {...fieldProps}
          ref={ref}
          disabled={processing}
          value={derivedValue}
        />
      );
    }
    return { [fld.field_name]: <Row>{returnField}</Row> };
  };

  const getDerivedFormField = (fld) => {
    if (isAdd && fieldsExcludedWhenAdd.indexOf(fld.field_name) > -1) {
      //skip excluded fields when add
      return null;
    }

    formOrdering.push(fld.field_name);
    //skip assigning ref for section title
    const isTitleField =
      ["read-only", "description"].indexOf(fld.field_type) > -1;
    const field = isTitleField
      ? getField(fld, refsArr.current[refCount])
      : getField(fld, refsArr.current[refCount++]);
    return field;
  };

  let refCount = 0;
  let formDefs = {};
  let formOrdering = [];
  if (Array.isArray(integrationTemplate)) {
    //pass in as an array, without order attribute
    integrationTemplate.forEach((fld) => {
      const field = getDerivedFormField(fld);
      if (field) {
        formDefs = { ...formDefs, ...field };
      }
      return;
    });
  } else {
    //inbound/outbound
    integrationTemplate.order.forEach((section) => {
      integrationTemplate[section].forEach((fld) => {
        const field = getDerivedFormField(fld);
        if (field) {
          formDefs = { ...formDefs, ...field };
        }
        return;
      });

      return;
    });
  }
  return (
    <>
      <IntegrationForm
        {...props}
        {...widthProps}
        formErrors={formErrors}
        formRef={formRef}
        fieldRefs={refsArr.current} //passing array
        formDefs={formDefs}
        formOrdering={formOrdering}
      />
    </>
  );
});
