import {signalSectionBottom, commonSection, inEnabled} from "./CommonFields";
import { rebuildSection} from "./IntegrationDefinitionUtils";
import {IntegrationCategories} from "./IntegrationConsts";

//--------------------------------------------------------------
// pagerduty
//--------------------------------------------------------------
export const pagerduty ={
    common: rebuildSection( commonSection, {
        integration: "pagerduty",
        category: IntegrationCategories.INCIDENT_MANAGEMENT,
        out_enabled: false,
        in_enabled: true
    }),
    signal : {
        top:  [inEnabled],
        APIConfig: [ {
            field_name: "username",
            required: true,
            label: "Username",
            db_field_name: "auth_username",
            field_type: "text",
        }, {
            field_name: "apiKey",
            required: true,
            label: "API Key",
            db_field_name: "auth_password",
            field_type: "text"
        }],
        bottom: signalSectionBottom,
        order: ["top", "APIConfig", "bottom"]  //ordering of ui sections
    }
}