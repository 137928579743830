import React, {
  useState,
  useContext,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

import { PageContext, DataServiceContext, TableCrud } from "athena-next-ui-lib";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { DialogDeployment } from "./DialogDeployment";

export const DeploymentTable = forwardRef((props, ref) => {
  const pageContext = useContext(PageContext);
  const dataServiceContext = useContext(DataServiceContext);

  const [listData, setListData] = useState(null);
  const [copyRow, setCopyRow] = useState(null);

  const deploymentDialog = useRef();

  useImperativeHandle(ref, () => ({
    loadData,
  }));

  const loadData = (toCallGetProfile) => {
    return Promise.resolve()
      .then(() => setListData(null))
      .then(() =>
        dataServiceContext.fetch("deployment/read", { sort: ["name"] })
      )
      .then((deploymentReadCall) => {
        if (deploymentReadCall.response.code === 200) {
          setListData(deploymentReadCall.data);
        } else {
          throw deploymentReadCall.response;
        }
      })
      .finally(() => {});
  };

  const getCopyLabel = (row) => {
    if (row?.id == copyRow?.id) {
      return " Copied ";
    } else {
      return "  Copy  ";
    }
  };

  const copyHandler = (row) => {
    //Show Copied for 2 seconds, then revert it back to Copy
    return Promise.resolve()
      .then(() => setCopyRow({ ...row }))
      .then(() => navigator.clipboard.writeText(row?.zapi_auth_token))
      .then(() =>
        setTimeout(() => {
          setCopyRow(null);
        }, 2000)
      );
  };

  const editDeploymentAction = (deployment) => {
    return Promise.resolve().then(() =>
      deploymentDialog.current.showDialog(deployment)
    );
  };

  const columnDefs = [
    { label: "Name", fldName: "name", width: "20%" },
    { label: "Deployment ID", fldName: "id", width: "20%" },
    { label: "Token", fldName: "zapi_auth_token", width: "60%" },
    // {label: "UI Persona", fldName: "ui_persona", width: '10%'}
  ];
  const rowActions = {
    edit: {
      handler: editDeploymentAction,
    },
    copy: {
      width: "80px",
      icon: faCopy,
      label: getCopyLabel,
      handler: copyHandler,
    },
  };

  return (
    <>
      <TableCrud
        loadData={loadData}
        listData={listData}
        columnDefs={columnDefs}
        rowActions={rowActions}
        maxHeight={"calc(100vh - 100px)"}
      />
      <DialogDeployment
        action={"EDIT"}
        ref={deploymentDialog}
        updateCallback={loadData}
      ></DialogDeployment>
    </>
  );
});
