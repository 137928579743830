
import React, {useState, useContext, useRef, useImperativeHandle, forwardRef} from 'react';
import {getIntegrationIcon} from "/components-biz";

import {
    PageContext,
    DataServiceContext,
    TableCrud, Utilities,
    ThirdPartyLogo
} from 'athena-next-ui-lib'
import {faCopy, faCheck} from "@fortawesome/free-solid-svg-icons";
import {DialogAccessToken} from "./DialogAccessToken";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "../unified-integrations/in-out-bound.module.scss";




export const AccessTokenTable=forwardRef((props, ref)=>{
    const pageContext = useContext(PageContext);
    const dataServiceContext = useContext(DataServiceContext);

    const [listData, setListData]=useState(null);
    const [copyLabel, setCopyLabel] = useState( "Copy");

    const accessTokenDialog = useRef();

    useImperativeHandle(ref, ()=>({
        loadData
    }))



    const loadData=()=>{
        return Promise.resolve()
            .then(() =>dataServiceContext.fetch(
                'accesstoken/read',
                { "sort": ["deployment_id","name"] }
            ))
            .then((accessTokenReadCall) => {
                const userProfile = dataServiceContext.getUserProfile();
                const deploymentDefs = userProfile.deployments || [];

                if(accessTokenReadCall.response.code === 200){
                    const atData = [...accessTokenReadCall.data];

                    const derivedATData = atData.map(itm=>{
                        const matchingDeployment = deploymentDefs.find(dep=>dep.id===itm.deployment_id )||{};
                        return {...itm, deploymentName: matchingDeployment.name || "n/a"};
                    })

                    setListData(derivedATData);
                } else {
                    throw(accessTokenReadCall.response);
                }

            })
           
    }

    const copyHandler=( row )=> {
        //Show Copied for 2 seconds, then revert it back to Copy
        return Promise.resolve()
            .then(()=>navigator.clipboard.writeText(row?.token))
            .then(()=>setCopyLabel("Copied"))
            .then(()=>setTimeout(()=>{setCopyLabel("Copy")}, 2000));
    }

    const getDeleteConfirmObj=( obj )=> {
        return {
            title: "Delete Access Token",
            desc: <span>Are you sure you want to delete this accesss token: <b>{obj.name}</b>?</span>
        }
    }


    const editHandler=( accessToken )=> {
        return Promise.resolve()
            .then(() => accessTokenDialog.current.showDialog(accessToken));
    }


    const deleteHandler=( accessToken ) =>{
        return Promise.resolve()
            .then( () =>
                dataServiceContext.fetch(
                    'accesstoken/delete',
                    {token: accessToken.token}
                ) )
            .then( (response) => {
                try {
                    if( response.response.code === 200)
                        loadData();
                }
                catch(err) {
                    console.log("Err:"+err.message)
                }
            
                return response;
            } );
    }

    const deriveNameAndToken = (row) => {
        return <>
            {row.name && <div><b style={{fontSize:"16px"}}>{row.name}</b></div>}
            <div className={styles.tokenContent}>{row.token} &nbsp;
                <div className={styles.copyIcon}
                     onClick={()=>{
                        copyHandler(row);
                    }}>
                    <FontAwesomeIcon icon={faCopy} size={"lg"}/>
                    &nbsp;{copyLabel}
                </div>
            </div>
        </>
    }

    const deriveIntegrationIcon = (row) => {

        const icon = getIntegrationIcon( row.integration );

        if(icon) {
            return <ThirdPartyLogo type={icon.logo} width={"50px"} theme={"dark"}/>
          // return <img src={path} {...icon} width={60} height={60}/>
        }  else {
            return null;
        }

    }


    const displayModifiedInfo = ( row ) => { 
        return <>
            <div><b>{Utilities.TZ.timeAgo(row.ts, pageContext.timeZone)}</b></div>
            <div style={{opacity:.75}}>by&nbsp;{row.modify_user_name}</div>
        </>;
    }

    const displayEnableStatus = (row) =>{
        return <>
            {row.disable && <div>&mdash;</div>}

            {!row.disable && <FontAwesomeIcon  icon={faCheck} bold color="green" size="xl"/> }
        </>;
    }


    const columnDefs = [
        {label: "", renderer: deriveIntegrationIcon, width: '60px', align: "center"},
        {label: "Name/ Token", renderer: deriveNameAndToken, fldName: "name", width: '50%'},
        {label: "Role", fldName: "role", width: '10%'},
        {label: "Deployment Name", fldName: "deploymentName", width: '20%'},
        {label: "Enabled", renderer: displayEnableStatus,  fldName: "disable", width: '10%', align:"center"},
        {label: "Modified", renderer:displayModifiedInfo,  width: '10%'}
    ];
    const rowActions = {
        edit: {
            handler: editHandler
        },
        delete: {
            handler: deleteHandler,
            confirm:  getDeleteConfirmObj
        }
    };

    return <>
        <TableCrud
            loadData={loadData}
            listData={listData}
            columnDefs={columnDefs}
            rowActions={rowActions}
            maxHeight={"calc(100vh - 100px)"}
        />
        <DialogAccessToken action={"EDIT"}
                     ref={accessTokenDialog}

                     updateCallback={loadData}
        />
    </>
})


