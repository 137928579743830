import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Button,
  PageContext,
  DataServiceContext,
  TableCrud,
  useModalDialog,
  Utilities,
} from "athena-next-ui-lib";
import {
  useWizardDialog,
  WizardContent,
  WizardTitlebar,
  IntegrationDefinitionMap
} from "/components-biz";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import styles from "./WizardDialog.module.scss";

export function IntegrationsListWizard(props) {
  const dataServiceContext = useContext(DataServiceContext);
  const pageContext = useContext(PageContext);
  const { openWizardDialog, closeWizardDialog } = useWizardDialog();
  const { openModalDialog } = useModalDialog();

  const deriveName = (row) => {
    return (
      <span className={styles.link} onClick={() => openIntegrationEditor(row)}>
        {row.name}
      </span>
    );
  };

  const deriveDeployment = (row) => {
    const userProfile = dataServiceContext.getUserProfile();
    const deploymentDefs = userProfile.deployments || [];
    const deployment = deploymentDefs.find((d) => d.id === row.db_schema) || {
      name: "",
      id: "",
    };

    return (
      <>
        <div>
          <b>{deployment.name}</b>
        </div>
        <div style={{ opacity: 0.75 }}>{deployment.id}</div>
      </>
    );
  };

  const deriveModifiedByInfo = (row) => {
    return (
      <>
        <div>
          <b>{Utilities.TZ.timeAgo(row.ts, pageContext.timeZone)}</b>
        </div>
        <div style={{ opacity: 0.75 }}>by&nbsp;{row.modify_user_name}</div>
      </>
    );
  };

  const [existingIntegrationsList, setExistingIntegrationsList] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    let filter = [
      `integration=${props.integration}`,
      `category=${props.category}`,
      "is_test!=true",
    ];
    if (props.selectedDeployment) {
      filter.push(`db_schema=${props.selectedDeployment}`);
    }

    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch("integration/read", {
          sort: ["-ts", "db_schema"],
          filter: filter,
        })
      )
      .then((integrationCall) => {
        if (integrationCall.response.code === 200) {
          setExistingIntegrationsList(integrationCall.data);
        }
        return integrationCall;
      });
  };

  const columnDefs = [
    {
      label: `Existing Integrations...`,
      renderer: deriveName,
      width: "35%",
      align: "left",
      verticalAlign: "top",
    },
    {
      label: "Deployment",
      renderer: deriveDeployment,
      width: "35%",
      verticalAlign: "top",
    },
    { label: "Modified", renderer: deriveModifiedByInfo, width: "30%" },
  ];

  const deleteHandler = (row) => {
    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch("integration/delete", { siid: row.siid })
      )
      .then(
        () =>
          row?.access_token?.length > 0 &&
          dataServiceContext.fetch("accesstoken/delete", {
            token: row.access_token,
          })
      )
      .then((response) => {
        // try {
        //     if( response.response.code === 200)
        //         loadData();
        // }
        // catch(err) {
        //     console.log("Err:"+err.message)
        // }
        // finally {
        //     return response;
        // }

        return loadData();
      })
      .then((loadDataCall) =>
        props.updateIntegrationCount(
          row.category,
          row.integration,
          loadDataCall.data.length
        )
      );
  };

  const canEdit = (row) => row.access === "crud";

  const rowActions = {
    edit: {
      isAllowed: canEdit,
      handler: (item) => openIntegrationEditor(item),
    },
    delete: {
      isAllowed: canEdit,
      handler: deleteHandler,
      confirm: {
        icon: faQuestionCircle,
        title: "Delete Integration",
        desc: "Are you sure you want to delete this integration?",
      },
    },
  };

  const openHelp = () => {
    window.open(
      props.help,
      "zebrium-help",
      "toolbars=0,width=640,height=480,left=200,top=200,scrollbars=1,resizable=1"
    );
  };

  const openIntegrationEditor = (integrationData) => {
    let commonFieldValues = getIntegrationDefaultFieldValues();

    if (Object.keys(integrationData).length === 0) {
      //when adding a new one
      delete commonFieldValues.siid;
    }
    const consolidatedFields = {
      ...defaultIntegrationData,
      ...commonFieldValues,
      ...integrationData,
    };

    //these fields are set by api implicitly
    ["in_ze_auth_token", "retrys", "timeout", "ts", "modify_user_name"].forEach(
      (fld) => {
        return delete consolidatedFields[fld];
      }
    );

    props.openWizard("integration-editor", {
      ...props,
      integrationData: consolidatedFields,
    });
  };

  const getIntegrationDefaultFieldValues = () => {
    const isAccessToken = props.category === "dashboard-extension";
    const definition = isAccessToken
      ? IntegrationDefinitionMap[props.integration + "DashboardExtension"]
      : IntegrationDefinitionMap[props.integration];
    const commonFields = {};
    const fields = Array.isArray(definition) ? definition : definition.common;
    fields
      .filter((f) => !!f.db_field_name)
      .map((fld) => {
        commonFields[fld.db_field_name] = fld.value;
      });
    return commonFields;
  };

  return (
    <>
      <WizardTitlebar {...props} title={`${props.wizardTitle} Integrations`}>
        <Button type={"primary"} onClick={() => openIntegrationEditor({})}>
          {"Create a New Integration"}
        </Button>
        &nbsp;
        <Button type={"secondary"} onClick={openHelp}>
          Help
        </Button>
      </WizardTitlebar>
      <WizardContent
        css={`
          ${styles.noPad} ${styles.list}
        `}
        styles={{ width: "50vw" }}
      >
        {existingIntegrationsList.length > 0 && (
          <TableCrud
            loadData={() => {}}
            listData={existingIntegrationsList}
            columnDefs={columnDefs}
            rowActions={rowActions}
            maxHeight={"40vh"}
          />
        )}
      </WizardContent>
    </>
  );
}

const defaultIntegrationData = {
  access_token: "",
  auth_header_prefix: "",
  auth_password: "",
  auth_scheme: "",
  auth_token: "",
  auth_username: "",
  category: "",
  db_schema: "",
  in_argument1: "",
  in_argument2: "",
  in_argument3: "",
  in_argument4: "",
  in_argument5: "",
  in_auth_password: "",
  in_auth_token: "",
  in_auth_username: "",
  in_enabled: false,
  in_region: "",
  in_webhook: "",
  //"in_ze_auth_token": "",
  integration: "",
  name: "",
  out_alert_on_new_incidents: true,
  out_argument1: "",
  out_argument2: "",
  out_argument3: "",
  out_argument4: "",
  out_argument5: "",
  out_email_list: "",
  out_enabled: true,
  out_event_type: "",
  out_webhook: "",
  service_group: "",
  //"siid": ""
};
