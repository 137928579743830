import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import Moment from "moment";
import {
  Button,
  Field,
  FieldTypes,
  useModalDialog,
  LoadingAnim,
  Utilities,
  ThirdPartyLogo,
} from "athena-next-ui-lib";

import dupeStyles from "./DeploymentMigrator.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEye as previewOn,
  faArrowRight as moveItemRight,
  faTimes as removeItem,
  faTrashUndo as undoChange,
} from "@fortawesome/pro-solid-svg-icons";

import { faEye as previewOff } from "@fortawesome/pro-regular-svg-icons";

export const DeploymentMigrator = forwardRef((props, ref) => {
  const {
    openSecondaryModalDialog,
    closeModalDialog,
    closeSecondaryModalDialog,
  } = useModalDialog();

  const [selectedSourceName, setSelectedSourceName] = useState({
    label: "",
    value: "",
  });
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedSourceLoading, setSelectedSourceLoading] = useState(false);
  const [sourcePreviewId, setSourcePreviewId] = useState();
  const [sourcePreviewItem, setSourcePreviewItem] = useState();

  const [selectedTargetName, setSelectedTargetName] = useState({
    label: "",
    value: "",
  });
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [selectedTargetLoading, setSelectedTargetLoading] = useState(false);
  const [targetPreviewId, setTargetPreviewId] = useState();
  const [targetPreviewItem, setTargetPreviewItem] = useState();

  const targetNameInputRef = useRef();

  useImperativeHandle(ref, () => ({
    save: () => summarize(),
  }));

  const lookupDeploymentId = (deploymentName) => {
    const d = props.deploymentNames.find((d) => d.value === deploymentName);
    return d?.id;
  };

  const copyAllItemsIntoTarget = () => {
    if (canInitiateCopy() === false) {
      return;
    }

    let list = Utilities.JS.deepCopy(selectedTarget);
    let elmId;
    selectedSource.forEach((category) => {
      const { elmGUID, target } = copyItemsIntoList(
        category.items,
        category,
        list
      );
      elmId = elmGUID;
      list = target;
    });

    setSelectedTarget(updateListOfValues("target", list));

    if (elmId) scrollItemIntoView(elmId);
  };

  const copyItemIntoTarget = (origin, category, _item) => {
    if (canInitiateCopy() === false) {
      return;
    }

    //find category in target tree; if not found, add the category
    //add the item to the category
    //sort the items in the category

    let itemsToCopy = [_item];
    if (!_item) {
      itemsToCopy = category.items;
    }

    const { elmGUID, target } = copyItemsIntoList(
      itemsToCopy,
      category,
      Utilities.JS.deepCopy(selectedTarget)
    );

    setSelectedTarget(updateListOfValues("target", target));

    if (elmGUID) setTimeout(() => scrollItemIntoView(elmGUID), 500);
  };

  const copyItemsIntoList = (itemsToCopy, category, list) => {
    let elmGUID;

    itemsToCopy.forEach((item, index) => {
      const tItem = {
        ...item,
        source: item._deploymentId,
        guid: `guid-target-${item._id}`,
      };
      let tCategory = list.find((c) => c._name === category._name);
      if (tCategory) {
        const itemAlreadyExists = tCategory.items.find(
          (itm) => itm._id === tItem._id
        );
        const itemSameNameAlreadyExists = tCategory.items.find(
          (itm) => itm._name === tItem._name
        );

        if (itemAlreadyExists) {
          //don't copy
          //scroll first item into view
          elmGUID = itemAlreadyExists.guid;
        } else if (itemSameNameAlreadyExists) {
          //replace
          const index = tCategory.items.findIndex(
            (itm) => itm._name === tItem._name
          );
          const oldItem = tCategory.items[index];

          let newItem = { guid: `guid-target-${oldItem._id}`, replace: true };

          Object.keys(oldItem).forEach((prop) => {
            if (prop !== "guid") {
              newItem["old_" + prop] = oldItem[prop];
              newItem[prop] = item[prop];
            } else {
            }
          });

          newItem.action = {
            id: newItem._id,
            object_type: newItem.object_type,
            operation: "create",
            source_schema: newItem.source_schema,
          };

          tCategory.items.splice(index, 1, newItem);

          //scroll first item into view
          elmGUID = newItem.guid;
        } else if (tCategory.items && tCategory.items.length) {
          tItem.add = true;
          tItem.action = {
            id: tItem._id,
            object_type: tItem.object_type,
            operation: "create",
            source_schema: tItem.source_schema,
          };
          tCategory.items.push(tItem);
          //scroll first item into view
          elmGUID = tItem.guid;
        } else {
          tItem.add = true;
          tItem.action = {
            id: tItem._id,
            object_type: tItem.object_type,
            operation: "create",
            source_schema: tItem.source_schema,
          };
          tCategory.items = [tItem];
          //scroll first item into view
          elmGUID = tItem.guid;
        }
      } else {
        tItem.add = true;
        tItem.action = {
          id: tItem._id,
          object_type: tItem.object_type,
          operation: "create",
          source_schema: tItem.source_schema,
        };
        tCategory = { ...category, items: [tItem] };
        list.push(tCategory);

        elmGUID = tItem.guid;
      }
    });

    return {
      target: list,
      elmGUID,
    };
  };

  const undoAllChangesOnTarget = () => {
    onUpdateTargetName([selectedTargetName]);
  };

  const removeItemFromTargetList = (origin, category, item) => {
    let target = Utilities.JS.deepCopy(selectedTarget);

    target = removeItemFromList(item, category, target);

    if (item.id === targetPreviewId) {
      setTargetPreviewId(null);
      setTargetPreviewItem(null);
    }

    setSelectedTarget(updateListOfValues("target", target));
  };

  const removeItemFromList = (item, category, list) => {
    let tCategoryIndex = list.findIndex((c) => c._name === category._name);
    let tCategory = list[tCategoryIndex];
    const targetDeploymentId = selectedTargetName.value;

    if (tCategory?.items?.length) {
      const tIndex = tCategory.items.findIndex((itm) => itm._id === item._id);
      if (tIndex > -1) {
        switch (true) {
          case item.replace === true:
            //restore old item
            let restoredItem = {};

            Object.keys(item).forEach((prop) => {
              if (prop.indexOf("old_") === 0) {
                restoredItem[prop.replace(/old_/i, "")] = item[prop];
              }
            });

            tCategory.items.splice(tIndex, 1, restoredItem);

            break;
          case item._deploymentId !== targetDeploymentId:
            //a new item for the target deployment so remove from target tree
            tCategory.items.splice(tIndex, 1);
            break;
          case item._deploymentId === targetDeploymentId:
            //an item that exists in target deployment so mark as deleted from target tree
            tCategory.items.splice(tIndex, 1, {
              ...item,
              deleted: !item.deleted,
              action: {
                id: item._id,
                object_type: item.object_type,
                operation: "delete",
                source_schema: selectedTargetName.value,
              },
            });
            break;
          default:
            console.log("no action taken");
            break;
        }
      }
    }

    if (tCategory.items.length === 0) {
      list.splice(tCategoryIndex, 1);
    }

    return list;
  };

  const scrollItemIntoView = (elmGUID) => {
    const elm = document.getElementById(elmGUID);
    if (elm) elm.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  const handlePreviewItem = (type, category, item) => {
    if (type === "source") {
      setSourcePreviewItem(item);
      setSourcePreviewId(item._id);
    } else {
      setTargetPreviewItem(item);
      setTargetPreviewId(item._id);
    }
  };

  const fetchDeploymentSettings = (deploymentId) => {
    return Promise.resolve()
      .then(() => props.getDeploymentSettings(deploymentId))
      .then((getDeploymentSettingsCall) => {
        if (getDeploymentSettingsCall.response.code === 200) {
          return transformDataToUISchema(getDeploymentSettingsCall.data);
        } else {
          //throw(getDeploymentSettingsCall.response);
          openSecondaryModalDialog({
            title: "Error Retrieving Deployment Settings",
            type: "warn",
            content: <p>{getDeploymentSettingsCall.response.message}</p>,
          });

          return [];
        }
      });
  };

  const onUpdateSourceName = (selection) => {
    if (selection && selection.length) {
      return Promise.resolve()
        .then(() => setSelectedSourceLoading(true))
        .then(() => fetchDeploymentSettings(selection[0].value))
        .then((list) => {
          setSelectedSourceName(selection[0]);
          //setSelectedSource( updateListOfValues( "source", deployments[selection[0].value]) );
          setSelectedSource(updateListOfValues("source", list));
          setSourcePreviewId(-1);
          setSourcePreviewItem("");
        })
        .finally(() => setSelectedSourceLoading(false));
    }
  };

  const onUpdateTargetName = (selection) => {
    if (selection && selection.length) {
      //if the user has already selection a target deployment and wants to change it again,
      //warn them that changes will be lost
      if (selectedTargetName.value !== "") {
        openSecondaryModalDialog({
          title: "Are you sure?",
          type: "confirm",
          submitLabel: "Continue",
          content: <p>This action will discard any changes you have made.</p>,
          confirmationCallback: () => _onUpdateTargetName(selection),
        });
      } else {
        _onUpdateTargetName(selection);
      }
    }
  };

  const _onUpdateTargetName = (selection) => {
    if (selection && selection.length) {
      return Promise.resolve()
        .then(() => setSelectedTargetLoading(true))
        .then(() => fetchDeploymentSettings(selection[0].value))
        .then((list) => {
          setSelectedTargetName(selection[0]);
          setSelectedTarget(updateListOfValues("source", list));
          setTargetPreviewId(-1);
          setTargetPreviewItem("");
        })
        .finally(() => setSelectedTargetLoading(false));
    }
  };

  const updateListOfValues = (type, list) => {
    let lov = Utilities.JS.deepCopy(list);

    let id = 0;

    lov.forEach((category) => {
      category?.items.forEach((item) => {
        item.category = { ...category, items: [] };
        item.guid = `guid-${type}-${item._id}`;
      });

      //sort items a-z
      category?.items.sort((a, b) => (a._name > b._name ? 1 : -1));
    });

    //sort categories a-z
    lov.sort((a, b) => (a._name > b._name ? 1 : -1));

    return lov;
  };

  const drawTargetDeploymentField = () => {
    if (props.action === "create") {
      const update = (value) => {
        //console.log("targetName", value, selectedTargetName)
        setSelectedTargetName({ label: value, value: value });
      };

      return (
        <Field
          type={FieldTypes.TEXT}
          value={selectedTargetName.value || ""}
          error={sourceMatchesTargetError}
          placeholder={"Enter Deployment Name"}
          width={rightWidth}
          name={"target_name_input"}
          ref={targetNameInputRef}
          onUpdateValue={update}
        />
      );
    } else {
      return (
        <Field
          type={FieldTypes.SINGLE_SELECT}
          id={"targetNames"} //unique id needed for tooltip
          name={"dynamic-single-select"}
          error={sourceMatchesTargetError}
          response={{ code: 200 }}
          fieldWidth={rightWidth}
          showTooltip={false}
          placeholder={"Select..."}
          values={availableTargets}
          //value={availableTargets.filter( t=>t.value==="NEW")}
          onUpdateValue={(selection) => onUpdateTargetName(selection)}
        />
      );
    }
  };

  const handleSave = () => {
    const items = getItemsToSave();

    let payload = {
      target_schema: selectedTargetName.value,
      data: items.map((item) => item.action),
    };

    if (payload.data.length === 0) {
      delete payload.data;
    }

    console.log("save payload", payload);

    return Promise.resolve().then(() => props.save(payload));
  };

  const summarize = () => {
    if (canSave() === false) {
      return;
    }

    const action = props.action === "create" ? "Create" : "Update";

    const settings = {
      title: (
        <>
          {action} Deployment:&nbsp;&nbsp;
          <i className={dupeStyles.targetName}>{selectedTargetName.label}</i>
        </>
      ),
      type: "confirm",
      submit: handleSave,
      submitLabel: "Save Changes",
      content: changeSetSummary,
      minWidth: "400px",
    };

    openSecondaryModalDialog(settings);
  };

  const promptToEnterTargetName = () => {
    openSecondaryModalDialog({
      title: "Provide a Deployment Name",
      type: "warn",
      submitLabel: "Ok",
      content: (
        <p>
          Please enter a deployment name before you copy items into your new
          deployment.
        </p>
      ),
    });
  };

  const promptToSelectTarget = () => {
    openSecondaryModalDialog({
      title: "Select a Target Deployment",
      type: "warn",
      submitLabel: "Ok",
      content: (
        <p>
          Please select a target deployment before you copy items into your new
          deployment.
        </p>
      ),
    });
  };

  const promptToDifferentiateSourceAndTarget = () => {
    openSecondaryModalDialog({
      title: "Source and Target Deployments are the Same",
      type: "warn",
      submitLabel: "Ok",
      content: (
        <p>
          Please select a target deployment that is different from the source
          deployment.
        </p>
      ),
    });
  };

  const promptNothingToSave = () => {
    openSecondaryModalDialog({
      title: "Nothing to Save!",
      type: "warn",
      submitLabel: "Ok",
      content: (
        <p>
          Nothing has changed in the target deployment since it was last saved.
        </p>
      ),
    });
  };

  const promptSaveWithoutSettings = () => {
    openSecondaryModalDialog({
      title: "Save Deployment without any Settings?",
      type: "OkCancel",
      submitLabel: "Save",
      submit: handleSave,
      content: (
        <p>
          You have not copied any settings into the target deployment. Do you
          want to save it anyway?
        </p>
      ),
    });
  };

  const canInitiateCopy = () => {
    if (sourceMatchesTargetError) {
      promptToDifferentiateSourceAndTarget();
      return false;
    } else if (props.action === "create") {
      //TODO this is a workaround as selectedTargetName somehow not reflecting user input.
      const targetNameInputValueObj = targetNameInputRef?.current?.getValue();
      const targetNameInputValue =
        targetNameInputValueObj?.["target_name_input"];
      if (targetNameInputValue === "") {
        promptToEnterTargetName();
        return false;
      }
    } else if (props.action === "update") {
      if (selectedTargetName.value === "") {
        promptToSelectTarget();
        return false;
      }
    } else {
      return true;
    }
  };

  const getItemsToSave = () => {
    let itemsToSave = [];

    selectedTarget.forEach((category) => {
      category.items.forEach((itm) => {
        if (
          itm.hasOwnProperty("replace") === true ||
          itm.hasOwnProperty("deleted") === true ||
          itm.hasOwnProperty("add") === true
        ) {
          itemsToSave.push(itm);
        }
      });
    });

    return itemsToSave;
  };

  const canSave = () => {
    if (sourceMatchesTargetError) {
      promptToDifferentiateSourceAndTarget();
      return false;
    } else if (getItemsToSave().length === 0) {
      if (props.action === "create") {
        promptSaveWithoutSettings();
      } else {
        promptNothingToSave();
      }

      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    //update state of preview icon in source list
    setSelectedSource(updateListOfValues("source", selectedSource));
  }, [sourcePreviewId]);

  useEffect(() => {
    //update state of preview icon in target list
    setSelectedTarget(updateListOfValues("target", selectedTarget));
  }, [targetPreviewId]);

  const changeSetSummary = useMemo(() => {
    //console.log(selectedTarget);

    return (
      <DeploymentTree
        summarizeChangeSet={true}
        type={"target"}
        source={selectedSource}
        target={selectedTarget}
        deploymentId={selectedTargetName.value}
      />
    );
  }, [selectedTarget]);

  const availableTargets = useMemo(() => {
    //filter out the selected source...
    //let targets = deploymentNames.filter(d => d.value !== selectedSourceName?.value);
    let targets = [...props.deploymentNames];

    //targets.unshift({label: "Create New Deployment", value: "NEW"});

    return targets;
  }, [selectedSource, selectedTarget]);

  const availableSources = useMemo(() => {
    //filter out the selected target
    //let sources = deploymentNames.filter(d => d.value !== selectedTargetName?.value);
    let sources = [...props.deploymentNames];

    return sources;
  }, [selectedSource, selectedTarget]);

  const sourceMatchesTargetError = useMemo(() => {
    if (selectedSourceName?.label === "" && selectedTargetName?.label === "") {
      return false;
    } else {
      return (
        selectedSourceName?.label.toLowerCase() ===
        selectedTargetName?.label.toLowerCase()
      );
    }
  }, [selectedSourceName, selectedTargetName]);

  const fullWidth = "900px";
  const leftWidth = "340px";
  const rightWidth = "340px";
  const rightColumn = { flex: `1 0 ${leftWidth}` };
  const leftColumn = { flex: `0 1 ${rightWidth}` };

  const helpText =
    props.action === "create"
      ? " to create a new deployment based on an existing deployment "
      : "to copy settings and rules between deployments";

  return (
    <div style={{ width: fullWidth }}>
      <div className={dupeStyles.row}>
        <p>
          Use this dialog {helpText}. Select a source deployment to see items
          you wish to copy. Select a target deployment to copy items into. Any
          setting or rule can be previewed by clicking on that item's icon..
        </p>
      </div>

      <div className={dupeStyles.row}>
        <div className={dupeStyles.column} style={leftColumn}>
          <div className={dupeStyles.sourceTitle}>
            <i>Source Deployment</i>
            {sourceMatchesTargetError && (
              <span className={dupeStyles.error}>Source and Target match!</span>
            )}
          </div>

          <div className={dupeStyles.deploymentSelectorRow}>
            <Field
              type={FieldTypes.SINGLE_SELECT}
              error={sourceMatchesTargetError}
              id={"sourceNames"} //unique id needed for tooltip
              name={"dynamic-single-select"}
              fieldWidth={leftWidth}
              response={{ code: 200 }}
              placeholder={"Select..."}
              showTooltip={false}
              values={availableSources}
              //value={[selectedSourceName]}
              onUpdateValue={(selection) => onUpdateSourceName(selection)}
            />
            <Button
              type={"secondary"}
              onClick={copyAllItemsIntoTarget}
              disabled={selectedSourceName === ""}
            >
              Copy&nbsp;All&nbsp;
              <FontAwesomeIcon icon={moveItemRight} />
            </Button>
          </div>

          <DeploymentTree
            type={"source"}
            performAction={copyItemIntoTarget}
            loading={selectedSourceLoading}
            source={selectedSource}
            target={selectedTarget}
            previewId={sourcePreviewId}
            deploymentId={selectedSourceName.value}
            setPreview={handlePreviewItem}
          />

          <Preview type={"source"} item={sourcePreviewItem} />
        </div>

        <div className={dupeStyles.column} style={rightColumn}>
          <div className={dupeStyles.targetTitle}>
            <i>Target Deployment</i>
            {sourceMatchesTargetError && (
              <span className={dupeStyles.error}>Source and Target match!</span>
            )}
          </div>

          <div className={dupeStyles.deploymentSelectorRow}>
            {drawTargetDeploymentField()}
            <Button
              type={"secondary"}
              onClick={undoAllChangesOnTarget}
              disabled={selectedTargetName === ""}
            >
              Undo&nbsp;All&nbsp;
              <FontAwesomeIcon icon={undoChange} />
            </Button>
          </div>

          <DeploymentTree
            type={"target"}
            performAction={removeItemFromTargetList}
            loading={selectedTargetLoading}
            source={selectedSource}
            target={selectedTarget}
            deploymentId={selectedTargetName.value}
            previewId={targetPreviewId}
            setPreview={handlePreviewItem}
          />

          <Preview type={"target"} item={targetPreviewItem} />
        </div>
      </div>

      {/*<div align={"right"}><Button onClick={summarize}>Summary</Button></div>*/}
    </div>
  );
});

DeploymentMigrator.defaultProps = {
  save: () => {
    alert("Save!");
  },
};

const DeploymentTree = (props) => {
  const takeAction = (type, category, item) => {
    props.performAction(type, category, item);
  };

  const setPreview = (evt, type, category, item) => {
    evt.stopPropagation();
    props.setPreview(type, category, item);
  };

  const buildItems = (source, target, category, items) => {
    if (items) {
      return (
        <div>
          {items.map((item, index) => {
            const isSelected =
              props.type === "source" &&
              (props.target || [])
                .find((cat) => cat._name === category._name)
                ?.items?.findIndex((itm) => itm._id === item._id) > -1;
            const selectedCSS = isSelected ? dupeStyles.selected : null;
            const fromSourceCSS =
              props.deploymentId !== item._deploymentId
                ? `${dupeStyles.fromSource}`
                : null;
            const deletedCSS =
              props.type === "target" && item.deleted
                ? dupeStyles.deleted
                : null;
            const css = `${dupeStyles.item} ${selectedCSS} ${fromSourceCSS} ${deletedCSS}`;
            const previewed = item._id === props.previewId;
            const previewCSS = previewed
              ? dupeStyles.previewOn
              : dupeStyles.previewOff;

            let label, actionButtonTooltip, actionButtonCSS, actionButtonIcon;
            if (item.replace === true) {
              actionButtonIcon = undoChange;
              actionButtonTooltip = "Undo Copy";
              actionButtonCSS = `${dupeStyles.actionIcon} ${dupeStyles.destructive}`;
              label = (
                <div className={dupeStyles.replaceItem}>
                  <span className={dupeStyles.sourceColor}>{item._name}</span>
                  &nbsp;
                  <i>from</i>
                  &nbsp;
                  <span className={dupeStyles.sourceColor}>
                    {item._deploymentName}
                  </span>
                  &nbsp;
                  <i>replaces</i>
                  &nbsp;
                  <span className={dupeStyles.targetColor}>{item._name}</span>
                </div>
              );
            } else if (item.deleted === true) {
              actionButtonIcon = undoChange;
              actionButtonTooltip = "Undo Delete";
              actionButtonCSS = `${dupeStyles.actionIcon} ${dupeStyles.destructive}`;
              label = (
                <div className={dupeStyles.replaceItem}>
                  <i>Remove</i>
                  &nbsp;
                  <span className={dupeStyles.targetColor}>{item._name}</span>
                  &nbsp;
                  <i>from</i>
                  &nbsp;
                  <span className={dupeStyles.targetColor}>
                    {item._deploymentName}
                  </span>
                </div>
              );
            } else if (props.deploymentId !== item._deploymentId) {
              actionButtonIcon = undoChange;
              actionButtonTooltip = "Undo Copy";
              actionButtonCSS = `${dupeStyles.actionIcon} ${dupeStyles.destructive}`;
              label = (
                <div className={dupeStyles.replaceItem}>
                  <i>Add</i>
                  &nbsp;
                  <span className={dupeStyles.sourceColor}>{item._name}</span>
                  &nbsp;
                  <i>from</i>
                  &nbsp;
                  <span className={dupeStyles.sourceColor}>
                    {item._deploymentName}
                  </span>
                </div>
              );
            } else if (props.type === "target") {
              actionButtonIcon = removeItem;
              actionButtonTooltip = "Delete Item";
              actionButtonCSS = `${dupeStyles.actionIcon} ${dupeStyles.destructive}`;
              label = item._name;
            } else if (isSelected) {
              actionButtonIcon = null;
              label = item._name;
            } else {
              actionButtonIcon = moveItemRight;
              actionButtonTooltip = "Copy Item";
              actionButtonCSS = `${dupeStyles.actionIcon} ${dupeStyles.constructive}`;
              label = item._name;
            }

            let previewButton = (
              <span
                className={previewCSS}
                onClick={(evt) => setPreview(evt, props.type, category, item)}
              >
                <FontAwesomeIcon
                  icon={item.selected ? previewOn : previewOff}
                  size={"lg"}
                />
              </span>
            );

            if (props?.summarizeChangeSet === true) {
              actionButtonIcon = null;
              previewButton = null;
            }

            const actionButton = actionButtonIcon ? (
              <span
                className={actionButtonCSS}
                onClick={() => takeAction(props.type, category, item)}
              >
                <FontAwesomeIcon
                  icon={actionButtonIcon}
                  size={"lg"}
                  title={actionButtonTooltip}
                />
              </span>
            ) : null;

            return (
              <div className={css} key={index} id={item.guid}>
                {previewButton}
                &nbsp;
                <span
                  className={dupeStyles.itemLabel}
                  onClick={() => takeAction(props.type, category, item)}
                >
                  {label}
                </span>
                {actionButton}
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  const buildCategories = (source, target) => {
    return (source || []).map((category, index) => {
      let actionButton = null;

      if (props.type === "source") {
        actionButton = (
          <span
            className={`${dupeStyles.actionIcon} ${dupeStyles.constructive}`}
            onClick={() => takeAction(props.type, category)}
            title={"Copy All"}
          >
            <FontAwesomeIcon icon={moveItemRight} size={"lg"} />
          </span>
        );
      }

      let items = category.items;
      if (props.summarizeChangeSet === true) {
        items = (items || []).filter((itm) => {
          return (
            itm.hasOwnProperty("replace") === true ||
            itm.hasOwnProperty("deleted") === true ||
            itm.hasOwnProperty("add") === true
          );
        });

        if (items?.length === 0) {
          return null;
        }
      }

      const css = category.selected
        ? `${dupeStyles.category} ${dupeStyles.selected}`
        : `${dupeStyles.category}`;
      return (
        <React.Fragment key={index}>
          <div
            className={css}
            onClick={() => takeAction(source, category, null)}
          >
            <span>{category._name}</span>
            &nbsp;
            {actionButton}
          </div>
          {buildItems(source, target, category, items)}
        </React.Fragment>
      );
    });
  };

  const categories = useMemo(() => {
    if (props.type === "source") {
      return buildCategories(props.source, props.target);
    } else {
      return buildCategories(props.target);
    }
  }, [props.type, props.source, props.target]);

  if (!props.source) {
    return null;
  } else if (props.loading) {
    return (
      <div className={`${dupeStyles.list}`} style={{ padding: "8px" }}>
        <LoadingAnim />
      </div>
    );
  } else {
    return (
      <div className={`${dupeStyles.list} ${dupeStyles[props.type]}`}>
        {categories}
      </div>
    );
  }
};

const Preview = (props) => {
  const preview = useMemo(() => {
    const drawHeader = (item) => {
      return (
        <div>
          <h3>{item._name}</h3>
          <div className={dupeStyles.lastModifiedRow}>
            Modified <strong>{Moment(item.ts).fromNow()}</strong> by{" "}
            <strong>{item.modify_user_name}</strong>
          </div>
        </div>
      );
    };

    const drawIntegrationHeader = (item) => {
      return (
        <div className={dupeStyles.integrationHeader}>
          <ThirdPartyLogo
            type={props.item.integration}
            width={"50px"}
            theme={"dark"}
          />
          <div>
            <h3>{item._name}</h3>
            <div>
              <i>{item.integration}</i>
            </div>
            <div className={dupeStyles.lastModifiedRow}>
              Modified <strong>{Moment(item.ts).fromNow()}</strong> by{" "}
              <strong>{item.modify_user_name}</strong>
            </div>
          </div>
        </div>
      );
    };

    const drawAdvancedHeader = (item) => {
      return (
        <div className={dupeStyles.integrationHeader}>
          <ThirdPartyLogo type={"zebrium"} width={"50px"} theme={"dark"} />
          <div>
            <h3>Root Cause Settings</h3>
            <p>
              Contains various settings that control how and when Zebrium
              displays and notifies alerts.
            </p>
          </div>
        </div>
      );
    };

    const drawTagRuleBlock = (item) => {
      let rules = (item.tag_rules || []).map((r, num) => {
        let content = (
          <>
            <span className={dupeStyles.ruleNum}>{num + 1}:</span>
            <div className={dupeStyles.rules}>
              {drawKeyValue(r, "tagr_svc_grps")}
              {drawKeyValue(r, "tagr_labels_regex")}
              {drawKeyValue(r, "tagr_etext_regex")}
            </div>
          </>
        );

        return <div className={dupeStyles.ruleSummary}>{content}</div>;
      });

      return (
        <>
          <div className={dupeStyles.tagTitle}>
            <div> {drawTagNotifyChannels(item)}</div>
          </div>
          <div>{rules}</div>
        </>
      );
    };

    const drawKeyValue = (r, key) => {
      console.log(r);
      const keysLookup = {
        tagr_svc_grps: "svcgrp",
        tagr_labels_regex: "labels",
        tagr_etext_regex: "text",
      };

      let displayPlusSign = false;

      if (key === "tagr_labels_regex" && r["tagr_svc_grps"]) {
        displayPlusSign = true;
      }

      if (
        key === "tagr_etext_regex" &&
        (r["tagr_svc_grps"] || r["tagr_labels_regex"])
      ) {
        displayPlusSign = true;
      }

      if (!r[key]) {
        return null;
      } else {
        return (
          <div className={dupeStyles.ruleParts}>
            {displayPlusSign === true && (
              <div className={dupeStyles.ruleAnd}>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            )}
            <div className={dupeStyles.rulePart}>
              <span className={dupeStyles.ruleKey}>{keysLookup[key]}</span>
              <span className={dupeStyles.ruleValue}>{r[key]}</span>
            </div>
          </div>
        );
      }
    };

    const drawTagNotifyChannels = (item) => {
      if (!item.channelDisplayNames || item.channelDisplayNames.length === 0) {
        return null;
      } else {
        return (
          <span className={dupeStyles.channel}>
            <b>alert:</b>&nbsp;{item.channelDisplayNames.join(", ")}
          </span>
        );
        //return row.channelDisplayNames.map( c => <span className={dupeStyles.channel}>{c}</span> )
      }
    };

    switch (props.item?._category) {
      case "integration":
        return drawIntegrationHeader(props.item);
      case "advanced":
        return drawAdvancedHeader(props.item);
      case "tag-manual":
      case "tag-routing":
      case "tag-evts_int":
      case "tag-exclude":
      case "tag-user_driven":
        return (
          <>
            {drawHeader(props.item)}
            {drawTagRuleBlock(props.item)}
          </>
        );
      default:
        return null; //<div>No preview available</div>
    }
  }, [props.item]);

  return (
    <div className={`${dupeStyles.previewOuter} ${dupeStyles[props.type]}`}>
      <div className={dupeStyles.preview}>{preview}</div>
    </div>
  );
};

const CategoryDisplayNames = {
  integration: "Integrations",
  "tag-manual": "Manual Tags",
  "tag-user_driven": "Custom Alert Rules",
  "tag-routing": "Suggested Alert Routing Rules",
  "tag-include": "Include Rules",
  "tag-exclude": "Exclude Rules",
  "tag-evts_int": "Include Rules",
  advanced: "Zebrium Settings",
};

function transformDataToUISchema(data) {
  let api_transform_1 = {};
  let output = [];

  data.forEach((obj) => {
    let type = obj["object_type"];
    let deploymentId = obj["source_schema"];
    let itm = obj.data;

    switch (type) {
      case "integration":
        if (!api_transform_1.hasOwnProperty(type)) {
          api_transform_1[type] = [];
        }
        api_transform_1[type].push({
          ...itm,
          _type: "integration",
          _name: itm.name,
          _deploymentId: deploymentId,
          _deploymentName: deploymentId,
          _id: itm.siid,
          _category: type,
          object_type: type,
          source_schema: deploymentId,
        });
        break;
      case "tag":
        const _type = `${type}-${itm.tag_type}`;
        if (!api_transform_1.hasOwnProperty(_type)) {
          api_transform_1[_type] = [];
        }
        api_transform_1[_type].push({
          ...itm,
          _type: "tag",
          _name: itm.tag_name,
          _deploymentId: deploymentId,
          _deploymentName: deploymentId,
          _id: itm.tag_id,
          _category: _type,
          object_type: type,
          source_schema: deploymentId,
        });
        break;
      case "advanced":
        if (!api_transform_1.hasOwnProperty(type)) {
          api_transform_1[type] = [];
        }
        api_transform_1[type].push({
          ...itm,
          _type: "advanced",
          _name: itm.name,
          _deploymentId: deploymentId,
          _deploymentName: deploymentId,
          _id: itm.id,
          _category: type,
          object_type: type,
          source_schema: deploymentId,
        });

        break;
    }
  });

  Object.keys(api_transform_1).forEach((key) => {
    const cat = api_transform_1[key];

    let category = {
      _name: CategoryDisplayNames[key],
      items: cat,
    };

    output.push(category);
  });

  return output;
}
