import React, {
  useContext,
  useState,
  useEffect,
} from "react";
import Moment from "moment";
import {
  Button,
  useModalDialog,
  DataServiceContext,
} from "athena-next-ui-lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IntegrationDisplayLookup } from "./definitions/IntegrationDefinitionUtils";
import { INTEGRATION_TEST_TYPES } from "./definitions/IntegrationConsts";

const INTEGRATION_TEST_FLOWS = {
  CONNECT_AND_CREATE_EDIT_FLOW: 0,
  CREATE_ONLY_EDIT_FLOW: 1,
  CONNECT_AND_CREATE_NEW_FLOW: 2,
  CREATE_ONLY_NEW_FLOW: 3,
};

const TEST_STEPS = {
  initialize: "step-initialize",
  createIntegration: "step-create-integration",
  testConnection: "step-test-connection",
  createIncident: "step-create-incident",
  deleteIntegration: "step-delete-integrration",
  testComplete: "step-test-complete",
  testSuccess: "step-test-success",
  testFailed: "step-test-failed",
};

const {
  CONNECT_AND_CREATE_EDIT_FLOW: connectAndCreateEditFlow,
  CONNECT_AND_CREATE_NEW_FLOW: connectAndCreateNewFlow,
  CREATE_ONLY_EDIT_FLOW: createOnlyEditFlow,
  CREATE_ONLY_NEW_FLOW: createOnlyNewFlow,
} = INTEGRATION_TEST_FLOWS;

const STEPS_BY_FLOW = {
  connectAndCreateEditFlow: [
    TEST_STEPS.initialize,
    TEST_STEPS.createIntegration,
    TEST_STEPS.testConnection,
    TEST_STEPS.createIncident,
    TEST_STEPS.deleteIntegration,
    TEST_STEPS.testComplete,
  ],
  connectAndCreateNewFlow: [
    TEST_STEPS.initialize,
    TEST_STEPS.createIntegration,
    TEST_STEPS.testConnection,
    TEST_STEPS.createIncident,
    TEST_STEPS.deleteIntegration,
    TEST_STEPS.testComplete,
  ],
  createOnlyEditFlow: [
    TEST_STEPS.initialize,
    TEST_STEPS.createIntegration,
    TEST_STEPS.createIncident,
    TEST_STEPS.deleteIntegration,
    TEST_STEPS.testComplete,
  ],
  createOnlyNewFlow: [
    TEST_STEPS.initialize,
    TEST_STEPS.createIntegration,
    TEST_STEPS.createIncident,
    TEST_STEPS.deleteIntegration,
    TEST_STEPS.testComplete,
  ],
};

export const TestIntegration = (props) => {
  const dataServiceContext = useContext(DataServiceContext);
  const { openModalDialog } = useModalDialog();
  const { sharedData, fieldProps, fld } = props;

  const [testButtonClicked, setTestButtonClicked] = useState(false);

  const [testAborted, setTestAborted] = useState(false);

  const [currentStep, setCurrentStep] = useState(null);
  const [steps, setSteps] = useState(null);
  const [testIntegrationData, setTestIntegrationData] = useState(null);

  const [requestIdWithAPI, setRequestIdWithAPI] = useState(null); 

  const widthProps = {
    labelWidth: "150px",
    fieldWidth: "250px",
  };

  useEffect(() => {
    if (sharedData?.siid) {
      //edit flow
      if (props.testType === INTEGRATION_TEST_TYPES.CONNECT_AND_CREATE) {
        setSteps(STEPS_BY_FLOW.connectAndCreateEditFlow);
      } else {
        setSteps(STEPS_BY_FLOW.createOnlyEditFlow);
      }
    } else {
      //new flow
      if (props.testType === INTEGRATION_TEST_TYPES.CONNECT_AND_CREATE) {
        setSteps(STEPS_BY_FLOW.connectAndCreateNewFlow);
      } else {
        setSteps(STEPS_BY_FLOW.createOnlyNewFlow);
      }
    }
  }, [props.testType]);

  useEffect(() => {
    const stepCount = steps?.length || 0;
    if (testButtonClicked && stepCount > 0) {
      const stepIndex = steps.indexOf(currentStep);
      const progressValue = Math.floor(100 / stepCount) * stepIndex;
      switch (currentStep) {
        case TEST_STEPS.initialize:
          openTestIntegrationDialog({
            label: "Initializing...",
            value: progressValue,
            submitLabel: "Cancel",
            onOpen: advanceToNextStep,
          });
          break;
        case TEST_STEPS.createIntegration:
          openTestIntegrationDialog({
            label: "Creating Test Integration...",
            value: progressValue,
            submitLabel: "Cancel",
            onOpen: createIntegration,
          });
          break;
        case TEST_STEPS.testConnection:
          openTestIntegrationDialog({
            label: "Checking Connectivity",
            value: progressValue,
            submitLabel: "Cancel",
            onOpen: testConnection,
          });
          break;
        case TEST_STEPS.createIncident:
          openTestIntegrationDialog({
            label: "Creating Sample Detection",
            value: progressValue,
            submitLabel: "Cancel",
            onOpen: createIncident,
          });
          break;
        case TEST_STEPS.deleteIntegration:
          openTestIntegrationDialog({
            label: "Deleting Test Integration",
            value: progressValue,
            submitLabel: "Cancel",
            onOpen: deleteIntegration,
          });
          break;
        case TEST_STEPS.testComplete:
          openTestIntegrationDialog({
            label: (
              <>
                <strong>Test Succeeded!</strong>
                <p>check your dashboard for the sample alert</p>
              </>
            ),
            value: 100,
            submitLabel: "Close",
          });
          break;
      }
    }
  }, [currentStep, steps]);

  const cleanUpCallback = () => {
    //delay 2 seconds in case delete integration is invoked through Cancel flow
    setTimeout(() => {
      !testAborted && testIntegrationData && deleteIntegration(true);
    }, 2000);
  };

  useEffect(() => {
    //this handles case when Cancel is clicked in the middle of integration creation
    if (testIntegrationData?.hasOwnProperty("siid") && testAborted) {
      return deleteIntegration(true);
    }
  }, [testIntegrationData]);

  useEffect(() => {
    if (testAborted) {
      let apiToAbort = null;
      //abort currentStep api call
      switch (currentStep) {
        case TEST_STEPS.createIntegration:
          // apiToAbort = "integration/create";
          //do not abort because we need to delete the newly created integration regardless of test result
          break;
        case TEST_STEPS.testConnection:
          apiToAbort = "integration/read/test";
          break;
        case TEST_STEPS.createIncident:
          apiToAbort = "integration/create";
          break;
        case TEST_STEPS.deleteIntegration:
          //no abort for delete, go ahead and delete integration
          break;
      }
      const requestId =
        requestIdWithAPI && apiToAbort && requestIdWithAPI[apiToAbort];
      return Promise.resolve()
        .then(
          () =>
            apiToAbort &&
            dataServiceContext.abortAPICallWithRequestId(
              apiToAbort,
              `${requestId}`
            )
        )
        .finally(() => {
          if (testIntegrationData) {
            deleteIntegration(true);
          }
          setTestButtonClicked(false);
        });
    }
  }, [testAborted]);

  const getNextStep = () => {
    const currentStepIndex = steps.indexOf(currentStep);
    if (currentStepIndex === -1 || currentStepIndex === steps.length - 1) {
      return steps[0];
    } else {
      return steps[currentStepIndex + 1];
    }
  };

  const advanceToNextStep = () => {
    if (testAborted) {
      return cleanUpCallback();
    }

    setCurrentStep(getNextStep());
  };

  const testConnection = () => {
    const siid = sharedData.siid || testIntegrationData.siid;

    const api = "integration/read/test";
    const requestId = dataServiceContext.deriveAPICallRequestId(api);
    setRequestIdWithAPI({ ...requestIdWithAPI, [api]: requestId });

    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch(api, { siid: siid, requestId: requestId })
      )
      .then((testCallResponse) => {
        if (testCallResponse.response.code === 200) {
          //success
          return advanceToNextStep();
        } else {
          //return advanceToNextStep();
          //delete integration also done through cancel flow
          setTestAborted(true);
          const err = { message: testCallResponse.response.message }
          throw err;
        }
      });
  };

  const createIncident = () => {
    //"Test alert for Grafana integration at 2023-01-31 22:46:08 UTC"
    const utcTS = Moment().utc().format("yyyy-MM-DD HH:mm:ss");
    const integrationType = IntegrationDisplayLookup[sharedData.integration];
    const title = `Test alert for ${integrationType} integration at ${utcTS} UTC`;

    const siid = sharedData.siid || testIntegrationData.siid;
    const api = "integration/create/incident";
    const requestId = dataServiceContext.deriveAPICallRequestId(api);
    setRequestIdWithAPI({ ...requestIdWithAPI, [api]: requestId });

    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch(api, {
          siid: siid,
          title: title,
          requestId: requestId,
        })
      )
      .then((createCallResponse) => {
        if (createCallResponse.response.code === 200) {
          //success
          return advanceToNextStep();
        } else {
          // return advanceToNextStep();
          //delete integration also done through cancel flow
          setTestAborted(true);
          const err = { message: createCallResponse.response.message }
          throw err;
        }
      });
  };

  const openTestIntegrationDialog = (args) => {
    const content = (
      <div style={{ textAlign: "center", margin: "10px 0" }}>
        {args.value === 100 && (
          <div>
            <FontAwesomeIcon icon={faCheck} size={"2x"} color={"#CDDC39FF"} />
          </div>
        )}
        <progress value={args.value} max="100">
          {args.label}
        </progress>
        <div>{args.label}</div>
      </div>
    );

    openModalDialog({
      title: "Testing Integration",
      type: "warn",
      submitLabel: args.submitLabel,
      submit: () => setTestAborted(true),
      onOpen: () => (args.onOpen ? args.onOpen() : {}),
      cancelCallback: () => setTestAborted(true),
      content: content,
    });
  };

  const createIntegration = () => {
    const payload = { ...sharedData };

    //go through encrypted fields, remove from payload if already set
    [
      "auth_password",
      "auth_token",
      "in_auth_password",
      "in_auth_token",
    ].forEach((encFld) => {
      if (payload[encFld]?.indexOf("...") === 0) {
        delete payload[encFld];
      }
    });

    if (payload.siid) {
      delete payload.siid;
    }

    const api = "integration/create";
    const requestId = dataServiceContext.deriveAPICallRequestId(api);
    setRequestIdWithAPI({ ...requestIdWithAPI, [api]: requestId });

    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch(
          api, //create
          {
            ...payload,
            name: payload.name + "-test",
            out_enabled: false,
            is_test: true,
            requestId: requestId,
          }
        )
      )
      .then((previousCall) => {
        if (previousCall.response.code === 200) {
          const integrationData = previousCall?.data?.[0] || {};
          setTestIntegrationData({ ...integrationData });
          return advanceToNextStep();
        } else {
          setTestAborted(true);
          const err = { message: previousCall.response.message };
          throw err;
        }
      });
  };

  //testIncomplete is true when user clicks on Cancel before test is done,
  // or either connection test or incident create api failed.
  const deleteIntegration = (testIncomplete) => {
    //integrated was created for test, needs to be removed when
    // - test is done with success, part of normal step
    // - user clicked on Cancel before it finishes
    // - api failed when testing connection or create incident

    const api = "integration/delete";
    const requestId = dataServiceContext.deriveAPICallRequestId(api);
    setRequestIdWithAPI({ ...requestIdWithAPI, [api]: requestId });
    return Promise.resolve()
      .then(() =>
        dataServiceContext.fetch(api, {
          siid: testIntegrationData.siid,
          requestId: requestId,
        })
      )
      .then((previousCall) => {
        if (previousCall.response.code === 200) {
          setTestIntegrationData(null);
          if (testIncomplete) {
            return;
          } else {
            return advanceToNextStep();
          }
        } else {
          const err = { message: previousCall.response.message }
          throw err;
        }
      });
  };

  return (
    <>
      <div style={{ width: widthProps.labelWidth }} />
      <div style={{ marginLeft: "5px" }}>
        <Button
          {...fieldProps}
          processing={testButtonClicked}
          onClick={() => {
            if (props.isIntegrationFormValid()) {
              setTestButtonClicked(true);
              setTestAborted(false);
              setTestIntegrationData(null);
              setCurrentStep(TEST_STEPS.initialize);
            }
          }}
        >
          {fld.label}
        </Button>
      </div>
    </>
  );
};
