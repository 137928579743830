import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useContext,
} from "react";
import styles from "/pages/auth/sign-in.module.scss";
import {
  Column,
  Field,
  Row,
  PageContext,
} from "athena-next-ui-lib";

export const PasswordReset = forwardRef((props, ref) => {

  const { stepId, className: clsName, onSubmit } = props;

  const [account, setAccount] = useState(null);
  const [passwordResetValid, setPasswordResetValid] = useState(false);
  const DOM_password = useRef();
  const DOM_confirmPassword = useRef();

  const setFocus = () => {
    document.getElementById("password-1").focus();
  };

  useImperativeHandle(ref, () => ({
    getNewPassword,
    setAccount,
    setFocus,
  }));

  const getNewPassword = () => {
    return DOM_password.current.getValue();
  };

  const validatePassword = () => {
    if (!DOM_password.current || !DOM_confirmPassword.current) return;

    const password1 = DOM_password.current.getValue();
    const password2 = DOM_confirmPassword.current.getValue();
    const isValid = (password1 || "").length > 0 && password1 == password2;
    setPasswordResetValid(isValid);

    if (props.passwordResetValidationCallback) {
      return props.passwordResetValidationCallback(isValid);
    }
  };

  const rowCSS = { css: { padding: "4px 8px" } };
  const resetTitle = (account || {}).invite_pending
    ? "Set Password"
    : "Reset Password";

  return (
    <div id={stepId} className={clsName}>
      <Column>
        <Row>
          <div className={styles.stepTitle}>
            <span>{resetTitle}</span>
            <span className={styles.subtitle}>
              {account?.name || props?.emailAddress || "unknown"}
            </span>
          </div>
        </Row>

        <Row align={"center"}>
          <Column>
            <Row {...rowCSS}>
              {props.visible && (
                <Field
                  type={"new-password"}
                  name={"new_pwd"}
                  required={true}
                  fieldId={"password-1"}
                  ref={DOM_password}
                  placeholder="Enter New Password"
                  onSubmit={onSubmit}
                  onUpdateValue={() => validatePassword()}
                  relatedFieldId={"password-2"}
                />
              )}
            </Row>
            <Row {...rowCSS}>
              {props.visible && (
                <Field
                  name={"new_pwd2"}
                  type={"new-password"}
                  ref={DOM_confirmPassword}
                  required={true}
                  fieldId={"password-2"}
                  placeholder="Reenter New Password"
                  onSubmit={onSubmit}
                  onUpdateValue={() => validatePassword()}
                  relatedFieldId={"password-1"}
                />
              )}
            </Row>
          </Column>
          <Column>{props.children}</Column>
        </Row>
      </Column>
    </div>
  );
});

PasswordReset.getInitialProps = async (ctx) => {
  return {};
};
