import React, {useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle} from 'react'

import { DataServiceContext, Field, FieldTypes} from "athena-next-ui-lib";

/***************************************************************
 * this component is used in the following 3 contexts
 * 1. inbound dialog, single select, with no All
 * 2. outbound dialog, single select, with All if owner role
 ****************************************************************/


export const DeploymentSelector=forwardRef( (props,ref )=> {
    const dataServiceContext = useContext(DataServiceContext);
    const { deploymentSelectorRef, selectedDeployment } = props;

    const [selectedValue, setSelectedValue] = useState([]);
    const [deployments, setDeployments] = useState(null);


    const [response, setResponse] = useState({code: 0});

    const isOwnerRole = dataServiceContext.getUserProfile().role == "owner";
    const currentDeployment= dataServiceContext.getUserProfile().deployment_id;

    useEffect(()=>{
        if( !deployments?.length) return;

        //derive selectedValue
        let matchingDeployment = [];
        if( selectedDeployment && selectedDeployment?.length>0 ) {
            matchingDeployment = deployments.filter(dep=>dep.id === selectedDeployment);
        }
        if( matchingDeployment.length<1) {
            matchingDeployment = deployments.filter(dep=>dep.id === currentDeployment);
        }

        setSelectedValue(matchingDeployment)

    }, [selectedDeployment, deployments])



    const onUpdateValue=(selObjArray)=>{
        if( selObjArray?.length>0 && props.onUpdateValue ) {
            props.onUpdateValue(selObjArray);
        }
    }


    const defaultContentProps = {
        ...props,
        type: FieldTypes.SINGLE_SELECT,
        truncateDisplayBeyond: 30,
        ref: deploymentSelectorRef,
        value: selectedValue,
        values: deployments,
        response: response,
        onUpdateValue:onUpdateValue,
        selectAllLabel: "All",
        required: true
    }

    const [content, setContent] = useState(<Field
        {...defaultContentProps}
    />);



    // useEffect(()=>{
    //     if( deployments && response.code != 0) {
    //         setContent(
    //             <Field
    //                 {...defaultContentProps}
    //                 response={response}
    //             />
    //         )
    //     }
    //
    // },[deployments, response])

    useEffect(()=>{
        setContent(
            <Field
                {...defaultContentProps}
                value={selectedValue}
                response={response}
            />
        )

    },[selectedValue])


    // useEffect(()=>{
    //     if( !deployments ||  deployments?.length == 0) return;
    //     let foundInList = null;
    //
    //     if( typeof selectedDeployments != "string" ) {
    //         //if null
    //         foundInList = deployments.find(dep=>dep.id == currentDeployment);
    //         if( foundInList ) {
    //             setSelectedValue(currentDeployment);
    //         } else {
    //             setSelectedValue(deployments[0].id);
    //         }
    //
    //     } else {
    //         const matchingDeploymentId = selectedDeployments=="" ? "all" : selectedDeployments;
    //
    //         foundInList = deployments.find(dep=>dep.id == matchingDeploymentId);
    //         if( foundInList ) {
    //             setSelectedValue(matchingDeploymentId);
    //         } else {
    //             setSelectedValue(deployments[0].id);
    //         }
    //     }
    //
    // },[selectedDeployments, deployments])

    useEffect(()=>{
        loadDeployments();
    },[])


    const loadDeployments=()=>{
        const userProfile =  dataServiceContext.getUserProfile();
        const upDeployments =userProfile.deployments.filter(dp=>["admin", "owner", "super_user"].indexOf(dp.role)>-1)// isOwnerRole ? userProfile.deployments : userProfile.deployments.filter(dp=>dp.role=="admin");
        const dataOut = upDeployments.map(itm=>{
            return {...itm, value: itm.id, label: itm.name}
        })

        setDeployments([...dataOut]);
        setResponse({code: 200});
        return;
    }


    return <>
        {content}
    </>;
})
