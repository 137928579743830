import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Button,
  DataServiceContext,
  getErrorMessageDisplay,
  useModalDialog,
} from "athena-next-ui-lib";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserMgmtUtils from "../../.utilities/userMgmtUtils";
import { UserForm } from "./UserForm";

export const DialogUser = forwardRef((props, ref) => {
  const dataServiceContext = useContext(DataServiceContext);
  const { openModalDialog } = useModalDialog();

  const [action, setAction] = useState(props.action); //ADD, EDIT,  ADD as default
  const [processing, setProcessing] = useState(false);

  const userFormRef = useRef();

  useImperativeHandle(ref, () => ({
    showUserDialog,
  }));

  useEffect(() => {
    setAction(props.action);
  }, [props.action]);

  const saveUser = () => {
    let values = userFormRef.current.getFormValues();

    if (values.errors.length > 0) {
      //form contains error(s), throw error
      const err = { message: getErrorMessageDisplay(values.errors) }
      throw err;
    }

    const isAdd = action === "ADD";
    const randPassword = UserMgmtUtils.generateRandomPassword();

    if (isAdd) {
      values = {
        ...values,
        password: randPassword,
        force_password_change: true,
      };
    } else {
      //in edit mode
      values = { ...values, force_password_change: false };
    }

    values.group_ids =
      (values.group_ids || "").length > 0 ? values.group_ids.split(",") : []; //convert string into array of strings

    const apiUrl = isAdd ? "user/create" : "user/update";

    return Promise.resolve()
      .then(() => dataServiceContext.fetch(apiUrl, values))
      .then((response) => {
        if (response.response.code === 200) {
          let responseData = [{ ...response.data[0], password: randPassword }];
          props.updateCallback && props.updateCallback(responseData, action);
        } else {
          const err = { message: response.response.message }
          throw err;
        }
      
      });
  };

  const showUserDialog = (userData) => {
    const isAdd = action === "ADD";
    const title = isAdd ? "Add User" : "Edit User";
    const submitLabel = isAdd ? "Add" : "Update";

    const modalDialogSettings = {
      title: title,
      icon: faUserPlus,
      submitLabel: submitLabel,
      submit: saveUser,
      maxWidth: "400px",
      content: (
        <UserForm
          ref={userFormRef}
          data={userData}
          processing={processing}
          action={action}
        ></UserForm>
      ),
    };

    return Promise.resolve()
      .then(() => setProcessing(true))
      .then(() => openModalDialog(modalDialogSettings))
      .then(() => userFormRef.current.showUserForm())
      .then(() => setProcessing(false));
  };

  if (props.action == "ADD") {
    return (
      <Button
        processing={processing}
        onClick={() => {
          return showUserDialog({});
        }}
      >
        <FontAwesomeIcon icon={faUserPlus} />
        <span>Add User</span>
      </Button>
    );
  } else {
    return null;
  }
});
