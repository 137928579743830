const defaultIconSize = {width:80,height:80};

export const getIntegrationIcon = (integrationName) => {
    let icon =  null;

    allIntegrationTilesDefault.forEach( category => {

        const {integrations} = category;

        integrations.forEach( integrationObj => {

            if(integrationObj.integration === integrationName){
                icon = integrationObj.icon;
                icon.title = integrationObj.title;
            }

        } )
    })

    return icon;

}

export const allIntegrationTilesDefault = [
    {
        categoryName: "Log Collectors",
        category:"log-collector",
        integrations: [
            {
                integration: "cloudwatch",
                help: "https://docs.zebrium.com/docs/setup/cloudwatch_otel/",
                title: "AWS CloudWatch Log Collector",
                tileTitle: "AWS CloudWatch",
                logCollectorID: "cloudwatch",
                wizardTitle: "AWS CloudWatch Log Collector",
                icon: {logo: "cloudwatch", size: {...defaultIconSize}},
                beta: true
            },
            {
                integration: "azure",
                help: "https://docs.zebrium.com/docs/setup/azure_monitor_otel/",
                title: "Azure Monitor Log Collector",
                tileTitle: "Azure Monitor",
                logCollectorID: "azure",
                wizardTitle: "Azure Monitor Log Collector",
                icon: {logo: "azure", size: {...defaultIconSize}},
                beta: true
            },
            {
                integration: "kubernetes",
                help: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/03_Log_Collectors_Uploads/kubernetes.html",
                title: "Kubernetes Log Collector",
                tileTitle: "Kubernetes",
                logCollectorID: "kubernetes-helm-3",
                wizardTitle: "Kubernetes Log Collector",
                icon: {logo: "k8s", size: {...defaultIconSize}}
            },
            {
                integration: "linux",
                help: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/03_Log_Collectors_Uploads/Linux_Collectors.html",
                title: "Linux Log Collector",
                tileTitle: "Linux",
                logCollectorID: "linux",
                wizardTitle: "Linux Log Collector",
                icon: {logo: "linux", size: {...defaultIconSize}}
            },
            {
                integration: "vsphere",
                help: "https://docs.zebrium.com/docs/setup/vsphere/",
                title: "VMware vSphere",
                tileTitle: "VMware vSphere",
                logCollectorID: "vsphere",
                wizardTitle: "VMware vSphere",
                icon: {logo: "vsphere", size: {...defaultIconSize}},
                beta: true
            },
            {
                integration: "windows",
                help: "https://docs.zebrium.com/docs/setup/windows_otel/",
                title: "Windows Log Collector",
                tileTitle: "Windows",
                logCollectorID: "windows",
                wizardTitle: "Windows Log Collector",
                icon: {logo: "windows", size: {...defaultIconSize}},
                beta: true
            },
            {
                integration: "file-upload",
                help: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/03_Log_Collectors_Uploads/File_Uploads_ze.html",
                title: "File Upload",
                tileTitle: "File Upload",
                logCollectorID: "file-upload",
                wizardTitle: "File Upload",
                icon: {logo: "file-upload", size: {...defaultIconSize}}
            },
            {
                integration: "generic-collector",
                help: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/Log_Collectors_Overview.html",
                title: "Other Log Collector",
                tileTitle: "Other",
                logCollectorID: "other-collectors",
                wizardTitle: "Other Log Collectors",
                icon: {logo: "lc-other", size: {...defaultIconSize}}
            },
        ]},
        {
            categoryName: "AIOps",
            category:"observability-dashboard",
            integrations: [ {
                integration:"sciencelogic",
                category: "dashboard-extension",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/ScienceLogic_Widget.html#Alert_Timeline_widgets",
                tileTitle: "Dashboard Widget",
                wizardTitle:"ScienceLogic Widget",
                icon:{logo: "sciencelogic", size:{...defaultIconSize}}

            },{
                integration: "sciencelogic",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/ScienceLogic.html#Overview",
                helpDetection: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/ScienceLogic.html#Send_SL1",
                helpSignal: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/ScienceLogic.html#Signals_SL1",
                tileTitle: "Events",
                wizardTitle:"ScienceLogic Dashboard",
                icon:{logo: "sciencelogic", size:{...defaultIconSize}}
            // },{
            //     integration: "pixie",
            //     category:"telemetry",
            //     help:"https://docs.zebrium.com/docs/monitoring/pixie_telemetry/",
            //     helpDetection: "https://docs.zebrium.com/docs/monitoring/pixie_telemetry/",
            //     helpSignal: "https://docs.zebrium.com/docs/monitoring/pixie_telemetry/",
            //     tileTitle: "Pixie",
            //     wizardTitle:"Collect Service Group Telemetry",
            //     icon:{logo: "pixie", size:{...defaultIconSize}}
            }]
        },

    {
        categoryName: "Observability Dashboards",
        category:"observability-dashboard",
        integrations: [{
                integration:"appdynamics",
                category:"observability-dashboard",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/AppDynamics_Dashboards.html",
                helpDetection:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/AppDynamics_Detection.html",
                helpSignal:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/AppDynamics_Signal.html",
                title: "AppDynamics",
                tileTitle: "AppDynamics",
                wizardTitle:"AppDynamics Dashboard",
                icon:{logo: "appdynamics", size:{...defaultIconSize}},
            },{
                integration:"datadog",
                category: "dashboard-extension",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Datadog_Dashboard_Widget.html",
                tileTitle: "Dashboard Widget",
                wizardTitle:"Datadog Widget",
                icon:{logo: "datadog", size:{...defaultIconSize}}

            }, {

                integration:"datadog",
                category:"observability-dashboard",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Datadog_Events_Metrics.html",
                helpDetection:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Datadog_Detection.html",
                helpSignal:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Datadog_Signal.html",
                tileTitle: "Events and Metrics",
                wizardTitle:"Datadog Dashboard",
                icon:{logo: "datadog", size:{...defaultIconSize}}

            }, {
                integration: "dynatrace",
                help: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Dynatrace.html",
                helpDetection: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Dynatrace_Detection.html",
                helpSignal: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Dynatrace_Signal.html",
                tileTitle: "Dynatrace",
                wizardTitle: "Dynatrace Dashboard",
                icon: {logo: "dynatrace", size: {...defaultIconSize}}
            }, {
                integration: "elastic",
                category: "dashboard-extension",
                help: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Elastic_Stack.html",
                helpDetection: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Elastic_Stack_Detection.html",
                tileTitle: "Elastic Stack",
                wizardTitle: "Elastic Stack",
                icon: {logo: "elastic", size: {...defaultIconSize}}
            },{
                integration:"grafana",
                category: "dashboard-extension",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/Grafana_Plugin.html",
                tileTitle: "Grafana Plugin",
                wizardTitle:"Grafana Plugin",
                icon:{logo: "grafana", size:{...defaultIconSize}}
            }, {
                integration: "newrelic",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/New_Relic.html",
                helpDetection: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/New_Relic_Detection.html",
                helpSignal: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/04_Observability_Integrations/New_Relic_Signal.html",
                tileTitle: "New Relic",
                wizardTitle:"New Relic Dashboard",
                icon:{logo: "newrelic", size:{...defaultIconSize}}
            }]
    }, {
        categoryName: "Notifications",
        category:"notification",
        integrations: [
            {
                integration:"email",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/06_Notifications/Email_Notifications.html",
                tileTitle: "Email",
                wizardTitle:"Email Notification",
                icon:{logo: "email", size:{...defaultIconSize}}
            }, {
                integration: "mattermost",
                help: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/06_Notifications/Mattermost_Notifications.html",
                tileTitle: "Mattermost",
                wizardTitle: "Mattermost Notification",
                icon: {logo: "mattermost", size: {...defaultIconSize}}
            },{
                integration:"teams",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/06_Notifications/MS_Teams_Notifications.html",
                tileTitle: "Microsoft Teams",
                wizardTitle:"Microsoft Teams Notification",
                icon:{logo: "teams", size:{...defaultIconSize}}
            }, {
                integration:"slack",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/06_Notifications/Slack_Notifications.html",
                tileTitle: "Slack",
                wizardTitle:"Slack Notification",
                icon:{logo: "slack", size:{...defaultIconSize}}
            }, {
                integration:"webexteams",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/06_Notifications/Webex_Teams_Notifications.html",
                tileTitle: "Webex Teams",
                wizardTitle:"Webex Teams Notification",
                icon:{logo: "webex", size:{...defaultIconSize}}
            }]
    },

    {
        categoryName: "Incident Management",
        category:"incident-management",
        integrations: [{
                integration:"opsgenie",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/Opsgenie.html",
                helpDetection:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/Opsgenie_Detection.html",
                helpSignal: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/Opsgenie_Signal.html",
                tileTitle: "Opsgenie",
                wizardTitle:"Opsgenie Incident Management",
                icon:{logo: "opsgenie", size:{...defaultIconSize}}
            },
            {
                integration:"opsramp",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/OpsRamp.html",
                helpDetection: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/OpsRamp_Detection.html",
                helpSignal:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/OpsRamp_Signal.html",
                tileTitle: "OpsRamp",
                wizardTitle:"OpsRamp Incident Manager",
                icon:{logo: "opsramp", size:{...defaultIconSize}}
            },
            {
                integration:"pagerduty",
                name:"pager-duty",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/PagerDuty.html",
                helpDetection:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/PagerDuty_Detection.html",
                helpSignal:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/PagerDuty_Signal.html",
                type:"png",size:{width:60,height:30},
                tileTitle: "PagerDuty",
                wizardTitle:"PagerDuty Incident Management",
                icon:{logo: "pagerduty", size:{...defaultIconSize}}
            },
            {
                integration:"victorops",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/VictorOps.html",
                helpDetection: "https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/VictorOps_Detection.html",
                helpSignal:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/05_Incident_Integrations/VictorOps_Signal.html",
                tileTitle: "VictorOps",
                wizardTitle:"VictorOps Incident Management",
                icon:{logo: "victorops", size:{...defaultIconSize}}
            }

        ]
    },

    {
        categoryName: "Webhooks",
        category:"webhook",
        integrations: [
            {
                integration:"generic",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/07_Webhooks/rca_incoming.html",
                tileTitle: "Incoming RCA",
                wizardTitle:"Incoming RCA Webhook",
                icon:{logo: "webhook", size:{...defaultIconSize}}
            },
            {
                integration:"webhook",
                help:"https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/07_Webhooks/rca_outgoing.html",
                tileTitle: "Outgoing RCA",
                wizardTitle:"Outgoing RCA Webhook",
                icon:{logo: "webhook", size:{...defaultIconSize}}
            }
        ]
    },


]
