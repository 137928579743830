import React, { useEffect, useState, useContext } from "react";
import {
  PageContext,
  DataServiceContext,
  Button,
  Column,
  ThirdPartyLogo,
} from "athena-next-ui-lib";

import {
  useWizardDialog,
  WizardTitlebar,
  WizardTabset,
  WizardTab,
  WizardButtonBar,
  CollectorDeploymentSelector,
} from "/components-biz";
import styles from "./wizard/WizardDialog.module.scss";
import LogCollectorUtils from "../../.utilities/LogCollectorUtils";

export const LogCollectorTile = (props) => {
  const { tile, index, categoryName, styles } = props;
  const { openWizardDialog, closeWizardDialog } = useWizardDialog();

  const openWizard = (data) => {
    const logCollectorDefinition = {
      help: "https://docs.sciencelogic.com/zebrium/latest",
      ...data,
    };

    openWizardDialog({
      content: (
        <LogCollectorDialog
          {...logCollectorDefinition}
          closeWizard={closeWizardDialog}
        />
      ),
    });
  };

  const drawLogCollectorTile = () => {
    return (
      <div
        className={styles.tile}
        key={index}
        data-count={tile.count}
        onClick={() => openWizard({ ...tile, categoryName: categoryName })}
      >
        <div className={styles.icon}>
          {tile.beta && <div className={styles.beta}>BETA</div>}
          <ThirdPartyLogo type={tile.icon.logo} width={"80px"} theme={"dark"} />
          {/* <img src={`../../images/logos/${tile.icon.filename}`} ${...tile.icon.size}/> */}
        </div>
        <div>
          <label>{tile.tileTitle}</label>
        </div>
      </div>
    );
  };

  return drawLogCollectorTile();
};

const LogCollectorDialog = (props) => {
  const dataServiceContext = useContext(DataServiceContext);
  const pageContext = useContext(PageContext);
  const [processing, setProcessing] = useState(false);
  const [content, setContent] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  const tabContentStyles = {
    maxHeight: "300px",
    minHeight: "300px",
    maxWidth: "600px",
  };
  const RCSettingsURL =
    dataServiceContext.getBaseUrl() + "/auth/report-settings";
  const userProfile = dataServiceContext.getUserProfile();

  useEffect(() => {
    setAuthToken(userProfile.zapi_auth_token);
  }, []);

  useEffect(() => {
    updateContent();
  }, [authToken]);

  const updateContent = () => {
    const logContent = LogCollectorUtils.getCollectorByID(
      props.logCollectorID,
      authToken,
      pageContext.env.ZAPI_URL,
      RCSettingsURL
    );
    const deploymentSelectorFld = (
      <CollectorDeploymentSelector
        label={"Deployment"}
        selectedDeployment={userProfile.deployment_id}
        onUpdateValue={(selObjArr) => {
          if (selObjArr?.[0]?.zapi_auth_token !== authToken) {
            setAuthToken(selObjArr?.[0]?.zapi_auth_token);
          }
        }}
        allowSelectAll={false}
      />
    );

    const wholeContent = (
      <Column>
        {deploymentSelectorFld}
        {logContent}
      </Column>
    );

    const theContent = (
      <WizardTab
        css={`
          ${styles.form}
        `}
        styles={tabContentStyles}
      >
        {wholeContent}
      </WizardTab>
    );

    setContent(theContent);
  };

  const openHelp = () => {
    window.open(
      props.help,
      "zebrium-help",
      "toolbars=0,width=640,height=480,left=200,top=200,scrollbars=1,resizable=1"
    );
  };

  return (
    <>
      <WizardTitlebar {...props} title={props.wizardTitle}>
        <Button type={"secondary"} onClick={openHelp}>
          Help
        </Button>
      </WizardTitlebar>

      <WizardTabset>{content}</WizardTabset>

      <WizardButtonBar>
        <div>
          <Button
            type={"primary"}
            processing={processing}
            disabled={processing}
            onClick={props.closeWizard}
          >
            Close
          </Button>
        </div>
      </WizardButtonBar>
    </>
  );
};
